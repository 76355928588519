import {
	ADD_ERROR,
	LOGIN,
	CLEAR_ERROR,
	LOGOUT,
	LOGIN_FAIL,
} from './AuthActions'

const authReducer = (state, action) => {
	switch (action.type) {
		case ADD_ERROR:
			return { ...state, errorMessage: action.payload }
		case LOGIN:
			return { errorMessage: '', token: action.payload }
		case CLEAR_ERROR:
			return { ...state, errorMessage: '' }
		case LOGOUT:
			return { token: null, errorMessage: '' }
		default:
			return state
	}
}

export default authReducer
