import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import { GET_WALLPAPERS } from './WallPaperAction'
import WallPaperReducer from './WallPaperReducer'

const getWallPapers = (dispatch) => () => {
	serverApi
		.get(appConfig.path_wallpaper)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_WALLPAPERS, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	WallPaperReducer,
	{ getWallPapers },
	{ wallpaperList: [] }
)
