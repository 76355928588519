import { OPEN_BUY_POINT, CLOSE_BUY_POINT } from './BuyPointAction'

const BuyPointReducer = (state, action) => {
	switch (action.type) {
		case OPEN_BUY_POINT:
			return {
				...state,
				isOpenBuyPoint: true,
				isOverPoint: action.payload,
			}
		case CLOSE_BUY_POINT:
			return { ...state, isOpenBuyPoint: false, isOverPoint: false }
		default:
			return state
	}
}

export default BuyPointReducer
