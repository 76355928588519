import { put, takeLatest } from 'redux-saga/effects'
import { updateCurrentPoint } from './PointControllerReducer'

// function* updatePoint() {
//     yield put({
//         type: updateCurrentPoint().type
//     })
// }

function* PointControllerSaga() {}

export default PointControllerSaga
