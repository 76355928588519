import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { APIs } from '../../../api/API'
import {
	doFetchTellerProfile,
	doFetchTellerProfileSuccess,
	doFetchTellerProfileFail,
	resetStatus,
} from './ChatReducer'

function* fetchTellerProfile(action) {
	try {
		const resultAPI = yield call(
			APIs.ChatAPI.fetchTellerProfile,
			action.payload
		)

		if (resultAPI.data.code === 0) {
			yield put({
				type: doFetchTellerProfileSuccess().type,
				payload: resultAPI.data.data,
			})
		} else {
			yield put({
				type: doFetchTellerProfileFail().type,
				payload: resultAPI.data.data,
			})
		}
	} catch (error) {
		console.log('%c  error:', 'color: #0e93e0;background: #aaefe5;', error)
		yield put({
			type: doFetchTellerProfileFail().type,
			payload: error,
		})
	} finally {
		yield put({ type: resetStatus().type, payload: {} })
	}
}

function* ChatSaga() {
	yield takeLatest(doFetchTellerProfile().type, fetchTellerProfile)
}

export default ChatSaga
