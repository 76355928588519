import {
	STATUS_TELLER,
	STATUS_ARROW,
	STATUS_ICON,
	TIMEZONE,
	GENDER,
	LAST_CHAT,
	DIMENSION_HEIGHT,
	STATUS_ICON_RANKING,
} from '../constants/constant'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { listTellerListTab } from '../constants/constant'
import request from 'axios'
import { messageTypes } from '../constants/messageTypes'
import * as Fetch from './Fetch'
import { STELLA_SITES_FOR_USERS, STELLA_WEBVIEW } from '../constants/constant'

export const openWebViewURL = (url, history) => {
	try {
		const hostname = new URL(url).hostname
		console.log(
			'%c  hostname:',
			'color: #0e93e0;background: #aaefe5;',
			hostname
		)
		const isStellaSites = STELLA_SITES_FOR_USERS.includes(hostname)
		const isWebView = STELLA_WEBVIEW.includes(hostname)
		if (isStellaSites) {
			window.location.replace(url)
			return
		}
		if (isWebView) {
			history.push('/webview', url)
			return
		}
		window.open(url, '_blank')
	} catch (error) {
		console.log('%c  error:', 'color: #0e93e0;background: #aaefe5;', error)
		//
	}
}

export const disableContextMenu = () => {
	window.addEventListener(
		'contextmenu',
		function (e) {
			e.preventDefault()
		},
		false
	)
}

export const calcHeight = ({
	isHaveHeader,
	isHaveFooter,
	isHaveBreadcrumb,
	additional = 0,
}) => {
	let height
	const windowHeight = window.outerHeight

	if (isHaveHeader) {
		height = windowHeight - DIMENSION_HEIGHT.HEADER
	}

	if (isHaveFooter) {
		height -= DIMENSION_HEIGHT.FOOTER
	}

	if (isHaveBreadcrumb) {
		height -= DIMENSION_HEIGHT.BREADCRUMB
	}

	return height - additional
}

export const downloadImage = (imageURL, name) => {
	const fileType = imageURL.split('.').pop()
	request({
		url: imageURL,
		method: 'GET',
		responseType: 'blob',
	}).then((response) => {
		const url = window.URL.createObjectURL(
			new Blob([response.data], { type: `image/${fileType}` })
		)
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', `${name}_wallpaper.${fileType}`)
		document.body.appendChild(link)
		link.click()
	})
}

export const getSelectedTab = (routerTab) => {
	switch (routerTab) {
		case listTellerListTab.tabTellerList:
			return 'tab=all'
		case listTellerListTab.tabTellerListFav:
			return 'tab=favorite'
		case listTellerListTab.tabTellerListRecommended:
			return 'tab=recommend'
		case listTellerListTab.tabTellerDebut:
			return 'tab=new'
		default:
			return 'tab=all'
	}
}

export const createParamsURL = (searchParam, params) => {
	let data = ''
	if (searchParam?.searchByName) {
		data = 'searchByName=' + searchParam.searchByName
		params.push(data)
	}
	if (
		searchParam?.searchByGenres &&
		searchParam?.searchByGenres.length !== 0
	) {
		data = 'searchByGenres=' + searchParam.searchByGenres.join(',')
		params.push(data)
	}
	if (
		searchParam?.searchByStyles &&
		searchParam?.searchByStyles.length !== 0
	) {
		data = 'searchByStyles=' + searchParam.searchByStyles.join(',')
		params.push(data)
	}
	if (
		searchParam?.searchByMethods &&
		searchParam?.searchByMethods.length !== 0
	) {
		data = 'searchByMethods=' + searchParam.searchByMethods.join(',')
		params.push(data)
	}
	if (
		searchParam?.searchByGender &&
		searchParam?.searchByGender.length !== 0
	) {
		data = 'searchByGender=' + searchParam.searchByGender.join(',')
		params.push(data)
	}
	if (searchParam?.searchByWaitingStatus) {
		params.push('searchByWaitingStatus=true')
	}
	if (searchParam?.searchBySampleVoice) {
		params.push('searchBySampleVoice=true')
	}
	return params
}

export const active = (selector) => {
	if (selector?.classList.contains('activeMessage')) {
		selector.classList.remove('activeMessage')
	} else {
		selector.classList.add('activeMessage')
	}
}

export const activeMessage = (selector) => {
	if (!selector) {
		return
	}

	if (!selector?.classList.contains('activeMessage')) {
		selector.classList.add('activeMessage')
	}
}

export const deactiveMessage = (selector) => {
	if (selector?.classList.contains('activeMessage')) {
		selector.classList.remove('activeMessage')
	}
}

export const disableClickOnMessage = () => {
	setTimeout(() => {
		const myMessages = document.querySelectorAll('.my_message')
		const tellerMessages = document.querySelectorAll('.teller_message')
		const messages = [...myMessages, ...tellerMessages]
		messages.length > 0 &&
			messages.map((item) => item.classList.add('disabledClick'))
	}, 700)
}

export const goToPreviousPage = () => {
	window.history.back()
}

export const lockScroll = (isOpenModal) => {
	isOpenModal
		? document
			.getElementsByTagName('body')[0]
			.setAttribute('style', 'overflow: hidden')
		: document
			.getElementsByTagName('body')[0]
			.removeAttribute('style', 'overflow: hidden')
}

export const showToast = (value) => {
	toast(`${value}`, {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}

export const getPlaceOfBirth = (data) => {
	let result
	if (!data?.placeOfBirth || data.placeOfBirth === 'UNKNOWN') {
		result = '--'
	} else {
		result = data.placeOfBirth
	}
	return result
}

export const getFormatTimeBirth = (time, currentFormat, afterFormat) => {
	return moment(time, currentFormat).format(afterFormat)
}

export const validateSpecialCharacter = (event) => {
	const regex = new RegExp('^[a-zA-Z]+$')
	const emoji =
		/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
	const key = String.fromCharCode(
		!event.charCode ? event.which : event.charCode
	)

	if (!regex.test(key) || key.match(emoji)) {
		event.preventDefault()
		return false
	}
}

export const validateEmoji = (event) => { }

export const getUserID = () => {
	return localStorage.getItem('userId')
}

export const scrollListAnswerToTop = () => {
	const listAnswer = document?.getElementById('list_answer_item')
	listAnswer.scrollTop = 0
}

export const getHeight = (id) => {
	return document.getElementById(id)?.clientHeight
}

export const showLoading = () => {
	document.getElementById('loading').style.display = 'initial'
}

export const hideLoading = () => {
	document.getElementById('loading').style.display = 'none'
}

export const handleRightMenu = () => {
	const rightMenuIcon = document.getElementById('ic_right_menu')
	const isOpen = rightMenuIcon?.src.includes('ico_close')
	const body = document.getElementsByTagName('body')[0]
	const rightMenuElement = document.getElementById('right_menu_container')
	if (isOpen) {
		rightMenuElement.style.display = 'none'
		rightMenuIcon.src = rightMenuIcon.src.replace('ico_close', 'ico_menu')
		rightMenuIcon.style.height = '22px'
		body.style.overflow = 'auto'
	} else {
		rightMenuElement.style.display = 'initial'
		rightMenuIcon.src = rightMenuIcon.src.replace('ico_menu', 'ico_close')
		rightMenuIcon.style.height = '32px'
		body.style.overflow = 'hidden'
	}
}

export const checkReLoginTime = (time) => {
	const reloginTime = moment(time, 'YYYYMMDDHHmm')
		.add(TIMEZONE, 'hours')
		.format('YYYYMMDDHHmm')
	const currentTime = moment().format('YYYYMMDDHHmm')
	return reloginTime > currentTime
}

export const checkShowStatusTeller = (data) => {
	if (
		data.status !== STATUS_TELLER.OFFLINE &&
		data.fortuneTellerStatus !== STATUS_TELLER.OFFLINE
	) {
		return true
	} else {
		return data?.reloginTime && checkReLoginTime(data.reloginTime)
	}
}

const formatReloginTime = (time) => {
	const reLoginHour = time.slice(8, 10)
	const reLoginMinute = time.slice(10, 12)
	return `${reLoginHour}:${reLoginMinute}`
}


const formatStringToDate = (string) => {
	const dateString = string;
	const year = +dateString.substring(0, 4);
	const month = +dateString.substring(4, 6);
	const day = +dateString.substring(6, 8);
	const reLoginHour = +dateString.slice(8, 10)
	const reLoginMinute = +dateString.slice(10, 12)
	return new Date(year, month - 1, day, reLoginHour, reLoginMinute);
}

const getStringCurrentDate = () => {
	var rightNow = new Date();
	return rightNow.toISOString().slice(0, 10).replace(/-/g, "");

}

const dateToString = (time) => {
	var rightNow = formatStringToDate(time);
	return `${rightNow.getFullYear()}${checkCharDate(rightNow.getMonth() + 1)}${checkCharDate(rightNow.getDate())}${checkCharDate(rightNow.getHours())}${checkCharDate(rightNow.getMinutes())}`
}

const checkCharDate = (value) => {
	if (value.toString().length !== 2) {
		return "0" + value
	}
	return value
}

Date.prototype.addHours = function (h) {
	this.setHours(this.getHours() + h);
	return this;
}




const formatReloginDate = (time) => {
	var currentDate = new Date();
	if (formatStringToDate(time).addHours(9).getTime() < currentDate.getTime()) {
		return '/ :'
	}
	time = dateToString(time)
	const reLoginDay = time.slice(6, 8)
	const reLoginMonth = time.slice(4, 6)
	const reLoginHour = time.slice(8, 10)
	const reLoginMinute = time.slice(10, 12)
	const reLoginYear = time.slice(0, 4)
	return `${reLoginYear}/${reLoginMonth}/${reLoginDay} ${reLoginHour}:${reLoginMinute}`
}
const getStatus = (data) => {
	let status = data?.status
	if (isNaN(status)) {
		status = data?.fortuneTellerStatus
	}
	return status
}

export const getStatusButtonChat = (data) => {
	let status = getStatus(data)

	switch (status) {
		case STATUS_TELLER.WAITING_ALL:
		case STATUS_TELLER.WAITING_ONLY_CHAT:
		case STATUS_TELLER.CHATTING:
			return true
		default:
			return false
	}
}
export const getStatusButtonCall = (data) => {
	let status = getStatus(data)

	switch (status) {
		case STATUS_TELLER.WAITING_ALL:
		case STATUS_TELLER.WAITING_ONLY_CALL:
			return true
		default:
			return false
	}
}

export const getTellerStatus = (data) => {
	if (!data) {
		return
	}

	let status = data.status

	if (isNaN(status)) {
		status = data.fortuneTellerStatus
	}
	const remainChatNumber = data.remainChatNumber
	const reLoginTime = formatReloginDate(data?.reloginTime ?? '')
	var localTime = moment.utc(reLoginTime).local().format('HH:mm');
	const lastTimeLogin = moment(data?.lastTimeLogin).fromNow()
	switch (status) {
		case STATUS_TELLER.WAITING_ALL:
		case STATUS_TELLER.WAITING_ONLY_CHAT:
		case STATUS_TELLER.WAITING_ONLY_CALL:
			return {
				statusTextColor: 'rgba(94, 202, 189, 1)',
				statusText: '今すぐ相談OK',
				statusIcon: STATUS_ICON.ONLINE,
			}
		case STATUS_TELLER.CHATTING:
			return {
				statusTextColor: 'rgba(255, 169, 30, 1)',
				statusText: `相談中(残${remainChatNumber})`,
				statusIcon: STATUS_ICON.CHATTING,
			}
		case STATUS_TELLER.CHATTING_FULL:
			return {
				statusTextColor: 'rgba(247, 130, 130, 1)',
				statusText: '相談中(満席)',
				statusIcon: STATUS_ICON.BUSY,
			}
		case STATUS_TELLER.CALLING:
			return {
				statusTextColor: 'rgba(247, 130, 130, 1)',
				statusText: '電話相談中',
				statusIcon: STATUS_ICON.CHATTING,
			}
		case STATUS_TELLER.OFFLINE:
			if (reLoginTime !== '/ :') {
				return {
					statusTextColor: 'rgba(94, 202, 189, 1)',
					statusText: `今日${localTime}〜予定`,
					statusIcon: STATUS_ICON.OFFLINE,
				}
			} else {
				return {
					statusTextColor: 'rgba(94, 202, 189, 1)',
					// statusText: `${lastTimeLogin}リクエスト受付中！`,
					statusText: `リクエスト受付中！`,
					statusIcon: STATUS_ICON.OFFLINE,
				}
			}

		default:
			break
	}
}


export const getTellerStatusRanking = (data) => {
	if (!data) {
		return
	}

	let status = data.status

	if (isNaN(status)) {
		status = data.fortuneTellerStatus
	}

	const remainChatNumber = data.remainChatNumber
	const reLoginTime = formatReloginDate(data.reloginTime)
	var localTime = moment.utc(reLoginTime).local().format('MM/DD HH:mm');
	const lastTimeLogin = moment(data?.lastTimeLogin).fromNow()
	switch (status) {
		case STATUS_TELLER.WAITING_ALL:
		case STATUS_TELLER.WAITING_ONLY_CHAT:
		case STATUS_TELLER.WAITING_ONLY_CALL:
			return {
				statusTextColor: 'rgba(94, 202, 189, 1)',
				statusText: '今すぐ相談OK',
				statusIcon: STATUS_ICON.ONLINE,
			}
		case STATUS_TELLER.CHATTING:
			return {
				statusTextColor: 'rgba(255, 169, 30, 1)',
				statusText: `相談中(残${remainChatNumber})`,
				statusIcon: STATUS_ICON.CHATTING,
			}
		case STATUS_TELLER.CHATTING_FULL:
			return {
				statusTextColor: 'rgba(247, 130, 130, 1)',
				statusText: '相談中(満席)',
				statusIcon: STATUS_ICON.BUSY,
			}
		case STATUS_TELLER.CALLING:
			return {
				statusTextColor: 'rgba(247, 130, 130, 1)',
				statusText: '電話相談中',
				statusIcon: STATUS_ICON.CHATTING,
			}
		case STATUS_TELLER.OFFLINE:
			if (reLoginTime !== '/ :') {
				return {
					statusTextColor: '#a9a4b3',
					statusText: `${localTime}〜予定`,
					statusIcon: STATUS_ICON.OFFLINE,
				}
			} else {
				return {
					statusTextColor: 'rgba(94, 202, 189, 1)',
					// statusText: `${lastTimeLogin}リクエスト受付中！`,
					statusText: ``,
					statusIcon: null
				}
			}

		default:
			break
	}
}

export const getPositionTeller = (data) => {
	const checkPosition = data.position - data.oldPosition
	const status = data.isNew ? 'NEW' : `前回${data.oldPosition}位`
	let result = { status }

	if (data.isNew) {
		result.arrow = STATUS_ARROW.NEW
		result.sizeWidth = 13
		result.sizeHeight = 14
	} else {
		if (checkPosition === 0) {
			result.arrow = STATUS_ARROW.KEEP
			result.sizeWidth = 15
			result.sizeHeight = 14
		} else if (checkPosition < 0) {
			result.arrow = STATUS_ARROW.UP
			result.sizeWidth = 12
			result.sizeHeight = 12
		} else {
			result.arrow = STATUS_ARROW.DOWN
			result.sizeWidth = 12
			result.sizeHeight = 12
		}
	}

	return result
}

export const paginateList = (list, chunk) => {
	let result = []
	for (let i = 0; i < list.length; i += chunk) {
		result.push(list.slice(i, i + chunk))
	}

	return result
}

export const isMyMessage = (fromId) => {
	const userId = localStorage.getItem('userId')
	return fromId === userId
}

const getDurationCall = (duration) => {
	return moment.utc(duration * 1000).format('mm:ss')
}

export const getLastChat = (data) => {
	const type = data.msgType
	const duration = data.duration
	const value = data.value
	let result = ''
	switch (type) {
		case messageTypes.SEND_FILE_FREE_UNOFFICIAL:
			result = LAST_CHAT.SEND_FILE_FREE_UNOFFICIAL
			break
		case messageTypes.SEND_FILE_UNOFFICIAL:
			result = LAST_CHAT.SEND_FILE_UNOFFICIAL
			break
		case messageTypes.SEND_STAMP_UNOFFICIAL:
			result = LAST_CHAT.SEND_STAMP_UNOFFICIAL
			break
		case messageTypes.SENT_FILE:
			result = LAST_CHAT.SENT_FILE
			break
		case messageTypes.SEND_FILE:
			result = LAST_CHAT.SEND_FILE
			break
		case messageTypes.SEND_STAMP:
			result = LAST_CHAT.SEND_STAMP
			break
		case messageTypes.SEND_GIFT:
			result = LAST_CHAT.SEND_GIFT
			break
		case messageTypes.SEND_STICKER:
			result = LAST_CHAT.SEND_STICKER
			break
		case messageTypes.NO_ACTION_LONG_TIME:
			result = LAST_CHAT.NO_ACTION_LONG_TIME
			break
		case messageTypes.NO_CONNECT_AVAILABLE:
			result = LAST_CHAT.NO_CONNECT_AVAILABLE
			break
		case messageTypes.CHARGE_TEXT_UNOFFICIAL:
			const userId = Fetch.getUserId()
			if (userId === data.toId) {
				result = LAST_CHAT.CHARGE_TEXT_UNOFFICIAL
			} else {
				result = value
			}
			break
		case messageTypes.CALL:
			if (value === 'BUSY_CALL') result = LAST_CHAT.BUSY_CALL
			if (value === 'CANCEL_CALL') result = LAST_CHAT.CANCEL_CALL
			if (value === 'LISTENED')
				result = `${LAST_CHAT.LISTENED} ${getDurationCall(duration)}`
			break
		case messageTypes.EXPECTED_REQUEST_CHAT:
			result = LAST_CHAT.EXPECTED_REQUEST_CHAT
			break
		case messageTypes.CANCEL_EXPECTED_REQUEST_CHAT:
			result = LAST_CHAT.CANCEL_EXPECTED_REQUEST_CHAT
			break
		case messageTypes.EXPECTED_REQUEST_CALL:
			result = LAST_CHAT.EXPECTED_REQUEST_CALL
			break
		case messageTypes.CLOSE_CHAT:
			result = LAST_CHAT.CLOSE_CHAT
			break
		case messageTypes.CANCEL_REQUEST_CHAT:
			result = LAST_CHAT.CANCEL_REQUEST_CHAT
			break
		case messageTypes.REQUEST_LIMIT_TIME:
			result = LAST_CHAT.REQUEST_LIMIT_TIME
			break
		default:
			result = value
			break
	}
	return result
}

export const getLastTimeContact = (serverTime) => {
	const gmt = moment.parseZone().utcOffset() / 60
	const time = moment(serverTime, 'YYYYMMDDHHmmss').add(gmt, 'hours')
	// before today
	if (time.isBefore(moment().startOf('day'))) {
		return time.format('MM/DD')
	} else {
		return time.format('HH:mm')
	}
}

export const getTellerIdFromSocketMessage = (message) => {
	const userId = localStorage.getItem('userId')
	return userId === message.fromId ? message.toId : message.fromId
}
export const getTimeFormat = (time, stringFormat = 'YYYY/MM/DD HH:mm') => {
	return moment(time, 'YYYYMMDDHHmm')
		.add(TIMEZONE, 'hours')
		.format(stringFormat)
}

export const getAgeUser = (bir) => {
	return parseInt(moment(bir, 'YYYYMMDD').fromNow()) - 1
}

export const getGenderUser = (gender) => {
	return GENDER[gender]
}

export const numberFormat = (point) => {
	try {
		return parseFloat(parseFloat(point).toFixed(2)).toLocaleString()
	} catch (e) {
		return point
	}
}

export const checkValidateEmail = (email) => {
	const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
	return regex.test(String(email).toLowerCase())
}

export const getParameterByName = (name, url) => {
	let urlString = url
	if (!urlString) {
		urlString = window.location.href
	}

	const parameterName = name.replace(/[\[\]]/g, '\\$&')
	const regex = new RegExp('[?&]' + parameterName + '(=([^&#]*)|&|#|$)') // eslint-disable-line
	const results = regex.exec(urlString)

	if (!results) {
		return ''
	}

	if (!results[2]) {
		return ''
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const convertLinkWebview = (url, token) => {
	const newUrl = url.replace(/%%token%%/g, token)

	if (document.location.protocol === 'http:') {
		return newUrl
	}

	return String(newUrl).replace(/^http:/, 'https:')
}

export const setCaretPosition = (ctrl, pos) => {
	// Modern browsers
	if (ctrl.setSelectionRange) {
		ctrl.focus()
		ctrl.setSelectionRange(pos, pos)

		// IE8 and below
	} else if (ctrl.createTextRange) {
		var range = ctrl.createTextRange()
		range.collapse(true)
		range.moveEnd('character', pos)
		range.moveStart('character', pos)
		range.select()
	}
}

export const closeDialogControlled = (
	event,
	reason = 'insideDialog',
	close = () => {
		console.log(
			'%c  Add your function to close this dialog:',
			'color: #0e93e0;background: #aaefe5;'
		)
	}
) => {
	// this dialog is only closed when user pressed on a button that is in dialog
	const isClickedOnOverlay = reason === 'backdropClick'

	if (isClickedOnOverlay) {
		return
	} else {
		close()
	}
}
