import { createSlice } from '@reduxjs/toolkit'
import { SAGAS_CONSTANTS } from '../../../sagas/sagasConstants'

const initialState = {
	data: {},
	status: SAGAS_CONSTANTS.STATUS.IDLE,
}

const ChatSlice = createSlice({
	name: 'Chat',
	initialState,
	reducers: {
		doFetchTellerProfile: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.LOADING
		},
		doFetchTellerProfileSuccess: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.SUCCESS
			state.data = action.payload
		},
		doFetchTellerProfileFail: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.FAILURE
			state.data = action.payload
		},
		resetStatus: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.IDLE
		},
	},
})

export const {
	doFetchTellerProfile,
	doFetchTellerProfileSuccess,
	doFetchTellerProfileFail,
	resetStatus,
} = ChatSlice.actions

export const ChatSelector = (state) => state.Chat

export default ChatSlice.reducer
