import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import {
	GET_CHAT_HISTORY,
	GET_MESSAGE_LIST,
	ADD_NEW_MESSAGE,
	ADD_NEW_CHAT_HISTORY,
	REQUEST_CHAT,
	GET_FAVORITE_LIST,
	REAL_TIME_MESSAGE,
	CANCEL_APPOINTMENT,
	CHECK_INCONVERSATION,
	CLOSE_CHAT,
	GET_RESPONSE_REQUEST_SOCKET,
	CLEAR_DATA_CHAT,
	DELETE_MSG_ERROR,
	RECONNECT_CHAT,
	BENCH_TIME,
	TELLER_READY_CHAT,
	READ_MESSAGE,
	REMOVE_UNFAVORITE_TELLER,
	FAVORITE_TELLER_SUCCESS,
	FAVORITE_TELLER_FAIL,
	FAVORITE_TELLER_CLEAR,
	DATA_LIST_CHAT,
	CHECK_USER_FIRST_CHAT_ONLINE,
} from './ChatActions'
import ChatReducer from './ChatReducer'
import * as Fetch from '../../util/Fetch'
import { SET_APPOINTMENT } from '../../constants/pathName'

const getChatHistory = (dispatch) => (params) => {
	serverApi
		.get(
			appConfig.path_chat_history +
			`?page=${params.page}&size=${params.size}&tab=${params.tab}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				if (response.data.data !== null) {
					dispatch({
						type: GET_CHAT_HISTORY,
						payload: response.data.data,
					})
				} else {
					dispatch({ type: GET_CHAT_HISTORY, payload: [] })
				}
			}
		})
		.catch((err) => {
			// window.location.reload();
		})
}
const getListChat = (dispatch) => (params) => {
	serverApi
		.get(
			appConfig.path_chat_history +
			`?page=${params.page}&size=${params.size}&tab=${params.tab}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				if (response.data.data !== null) {
					dispatch({
						type: DATA_LIST_CHAT,
						payload: response.data.data,
					})
				} else {
					dispatch({ type: DATA_LIST_CHAT, payload: [] })
				}
			}
		})
		.catch((err) => {
			// window.location.reload();
		})
}

const addNewChatHistory = (dispatch) => (message) => {
	dispatch({ type: ADD_NEW_CHAT_HISTORY, payload: message })
}

const getMessageList = (dispatch) => (params, type) => {
	if (type === 'GET_LIST_MESSAGE') {
		return serverApi
			.get(
				appConfig.path_chat_message_list +
				`?friendId=${params.friendId}&fromTime=${params.fromTime}&size=${params.size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_MESSAGE_LIST,
						payload: response.data.data.reverse(),
					})
				}
				return response
			})
			.catch((err) => {
				console.log(err)
			})
	} else if (type === 'GET_FAVORITE_MESSAGE') {
		return serverApi
			.get(
				appConfig.path_favorite_message +
				`?tellerId=${params.tellerId}&size=${params.size}&page=${params.page}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_FAVORITE_LIST,
						payload: response.data.data.reverse(),
					})
				}
				return response
			})
			.catch((err) => {
				console.log(err)
			})
	}
}

const updateMessage = (dispatch) => (data) => {
	if (!data?.length) {
		return
	}
	dispatch({
		type: GET_MESSAGE_LIST,
		payload: data,
	})
}

const addNewMessage = (dispatch) => (message) => {
	dispatch({ type: ADD_NEW_MESSAGE, payload: message })
}

const requestChat = (dispatch) => (tellerId, showPopupCountDown) => {
	const params = { toId: tellerId }
	return serverApi
		.post(appConfig.path_request_chat, params)
		.then((response) => {
			if (response.data.code === 0) {
				showPopupCountDown()
				dispatch({ type: REQUEST_CHAT, payload: response.data.data })
			}
			return response
		})

		.catch((error) => {
			console.error(error)
		})
}

const cancelRequestChat = (dispatch) => (requestId) => {
	serverApi.delete(`${appConfig.path_request_chat}/${requestId}/cancel`)
}

const setAppointment =
	(dispatch) =>
		(listExpected, tellerId, PopupSendSuccess, PopupSendFailed) => {
			const userId = Fetch.getUserId('userId')
			const params = {
				userId: userId,
				fortuneTellerId: tellerId,
				expectedTimes: listExpected,
				requestType: 1,
			}
			serverApi
				.post(`${appConfig.path_expected_request}`, params)
				.then((response) => {
					if (response.data.code === 0) {
						PopupSendSuccess.open()
						dispatch({
							type: SET_APPOINTMENT,
							payload: 'SET_APPOINTMENT_IS_DONE',
						})
					} else {
						PopupSendFailed.open()
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

const cancelAppointment = (dispatch) => (tellerId, requestType) => {
	const userId = Fetch.getUserId('userId')
	serverApi
		.delete(
			`${appConfig.path_expected_request}?userId=${userId}&fortuneTellerId=${tellerId}&requestType=${requestType}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: CANCEL_APPOINTMENT,
					payload: 'CANCEL_APPOINTMENT_IS_DONE',
				})
			}
		})
		.catch((error) => {
			console.error(error)
		})
}

const favoriteTeller = (dispatch) => (tellerId) => {
	const params = { friendId: tellerId }
	serverApi.post(appConfig.path_favorite, params).then((response) => {
		if (response.data.code === 0) {
			dispatch({ type: FAVORITE_TELLER_SUCCESS, payload: response.data })
		} else {
			dispatch({ type: FAVORITE_TELLER_FAIL, payload: response.data })
		}
		return response.data.code
	})
}

const unfavoriteTeller = (dispatch) => (tellerId) => {
	serverApi
		.delete(`${appConfig.path_favorite}?friendId=${tellerId}`)
		.then((response) => { })
}

const clearFavoriteTellerState = (dispatch) => () => {
	dispatch({ type: FAVORITE_TELLER_CLEAR })
}
const favoriteMessage = (dispatch) => (listMessage) => {
	const params = {
		addFavoriteRequest: listMessage,
	}
	serverApi
		.post(appConfig.path_favorite_message, params)
		.then((response) => {
			window.location.reload()
		})
		.catch((error) => {
			console.error(error)
		})
}

const unfavoriteMessage = (dispatch) => (listMessage) => {
	const params = {
		msgIds: listMessage,
	}
	serverApi
		.post(`${appConfig.path_favorite_message}/delete`, params)
		.then((response) => {
			window.location.reload()
		})
		.catch((error) => {
			console.error(error)
		})
}

const uploadFile = (dispatch) => (param) => {
	return serverApi
		.post(appConfig.path_upload_file, param, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then((response) => {
			if (response.data.code === 0) {
				return response.data
			}
			return response
		})
}

const getPrepareMsgId = (dispatch) => (toId, fileType, md5) => {
	const params = {
		toId,
		fileType,
		md5,
	}

	return serverApi
		.post(appConfig.path_prepare_send_file, params)
		.then((response) => {
			if (response.data.code === 0) {
				return response.data
			}
			return false
		})
}

const handleMessageRealTime = (dispatch) => (message, tab) => {
	dispatch({ type: REAL_TIME_MESSAGE, payload: { message, tab } })
}

const checkInconversation =
	(dispatch) =>
		(tellerId, userId, isDispatch = true) => {
			return serverApi
				.get(
					`${appConfig.path_check_inconversation}?fortuneTellerId=${tellerId}&userId=${userId}`
				)
				.then((response) => {
					// alert(JSON.stringify(response.data.data));
					if (response.data.code === 0 && isDispatch) {
						dispatch({
							type: CHECK_INCONVERSATION,
							payload: response.data.data,
						})
					}
					return response
				})

				.catch((error) => {
					console.error(error)
				})
		}

const closeChat = (dispatch) => (requestId) => {
	return serverApi
		.delete(`${appConfig.path_close_chat}${requestId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: CLOSE_CHAT, payload: response.data.data })
				localStorage.setItem('requestId', null)
			}
		})
		.catch((error) => {
			console.error(error)
		})
}

const getResponseRequestSocket = (dispatch) => (value) => {
	dispatch({ type: GET_RESPONSE_REQUEST_SOCKET, payload: value })
}

const checkPointUnlock = (dispatch) => (msgId) => {
	return serverApi
		.post(appConfig.path_check_point_unlock, { msgId })
		.then((response) => {
			if (response.data.code === 0) {
				return response
			}
		})
}

const unLockMessage = (dispatch) => (msgId) => {
	return serverApi
		.put(appConfig.path_unlock_message, { msgId })
		.then((response) => response)
}

const clearDataChat = (dispatch) => () => {
	dispatch({ type: CLEAR_DATA_CHAT })
}

const deleteMsgError = (dispatch) => (index) => {
	dispatch({ type: DELETE_MSG_ERROR, payload: index })
}

const reconnectChat = (dispatch) => async (tellerId) => {
	const params = {
		toId: tellerId,
	}
	await serverApi
		.post(`${appConfig.path_reconnect_chat}`, params)
		.then((response) => {
			dispatch({ type: RECONNECT_CHAT, payload: response.data.data })
		})
		.catch((error) => {
			console.error(error)
		})
}

const checkShowPopupBenchTime = (dispatch) => () => {
	serverApi
		.get(appConfig.path_bench_time)
		.then((response) => {
			if (response.data.code === 0)
				dispatch({ type: BENCH_TIME, payload: response.data.data })
		})
		.catch((error) => {
			console.error(error)
		})
}

const checkTellerReadyChat = (dispatch) => (tellerId) => {
	const userDeviceId = localStorage.getItem("browserID")
	serverApi
		.get(`${appConfig.path_ready_chat}/${tellerId}/readychat?userDeviceId=${userDeviceId}`)
		.then((response) => {
			if (response.data.code === 0) 
				dispatch({
					type: TELLER_READY_CHAT,
					payload: response.data.data,
				})
		})
		.catch((error) => {
			console.error(error)
		})
}

const readMessage = (dispatch) => (message) => {
	dispatch({ type: READ_MESSAGE, payload: message })
}

const removeUnfavoriteTeller = (dispatch) => (msgId) => {
	dispatch({ type: REMOVE_UNFAVORITE_TELLER, payload: { msgId } })
}

const hideMessage = () => (listMessage) => {
	const userId = Fetch.getUserId('userId')
	const friendIds = listMessage
	serverApi
		.put(`${appConfig.path_chat_history}/${userId}/hideLastChat`, { friendIds })
		.then((response) => {
			if (response.data.code === 0) {
				window.location.reload()
			}
		})
		.catch((error) => {
			console.error(error)
		})

}

const checkUserFirstTimeChatOnline = (dispatch) => () => {
	const userId = Fetch.getUserId('userId')
	serverApi
		.get(`${appConfig.path_partners}${userId}/firstTimeUserChatOnline`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: CHECK_USER_FIRST_CHAT_ONLINE,
					payload: response.data.data.isFirstTimeChatOnline,
				})
			}
		})
		.catch((error) => {
			dispatch({
				type: CHECK_USER_FIRST_CHAT_ONLINE,
				payload: false,
			})
		})
}

const updateIsCheckUserFirstChatOnline = (dispatch) => () => {
	dispatch({
		type: CHECK_USER_FIRST_CHAT_ONLINE,
		payload: false,
	})
}

export const { Provider, Context, actions } = createDataContext(
	ChatReducer,
	{
		checkTellerReadyChat,
		checkShowPopupBenchTime,
		reconnectChat,
		handleMessageRealTime,
		getChatHistory,
		addNewChatHistory,
		getMessageList,
		addNewMessage,
		cancelRequestChat,
		requestChat,
		setAppointment,
		cancelAppointment,
		favoriteTeller,
		unfavoriteTeller,
		favoriteMessage,
		unfavoriteMessage,
		uploadFile,
		getPrepareMsgId,
		checkInconversation,
		closeChat,
		getResponseRequestSocket,
		checkPointUnlock,
		unLockMessage,
		clearDataChat,
		updateMessage,
		deleteMsgError,
		readMessage,
		removeUnfavoriteTeller,
		clearFavoriteTellerState,
		hideMessage,
		getListChat,
		checkUserFirstTimeChatOnline,
		updateIsCheckUserFirstChatOnline
	},
	{
		reconnect: null,
		chatHistory: { data: [], hasMore: false },
		messageList: { data: [], hasMore: false },
		favoriteList: { data: [], hasMore: false },
		responseRequestChat: {},
		favorited: null,
		appointment: '',
		statusChat: {},
		closeChat: {},
		responseRequestSocket: {},
		benchTime: {},
		checkReadyChat: { readyChat: false, requestCounter: 0 },
		dataReadMsg: [],
		favoriteTellerData: { code: null, status: null },
		isUserFirstTimeChatOnline: null
	}
)
