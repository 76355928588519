import {
	GET_ALL_NOTIFICATION,
	GET_NOTIFICATION_FROM_TELLER,
	GET_NEWS,
	UPDATE_MAIN_TAB,
} from './NotificationActions'

const notificationReducer = (state, action) => {
	switch (action.type) {
		case GET_ALL_NOTIFICATION:
			return { ...state, notificationAll: action.payload }
		case GET_NOTIFICATION_FROM_TELLER:
			return { ...state, notificationTeller: action.payload }
		case GET_NEWS:
			return { ...state, listNews: action.payload }
		case UPDATE_MAIN_TAB: {
			return { ...state, mainTab: action.payload }
		}
		default:
			return state
	}
}

export default notificationReducer
