import * as Fetch from '../../util/Fetch'
import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import UserReviewReducer from './UserReviewReducer'
import { SEND_REVIEW } from './UserReviewActions'

const sendUserReview = (dispatch) => async (star, review, fortuneTellerId) => {
	const data = {
		star: star,
		reviewText: review,
		fortuneTellerId: fortuneTellerId,
	}
	serverApi
		.post(appConfig.path_reviews, data)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: SEND_REVIEW, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	UserReviewReducer,
	{ sendUserReview },
	{ data: [] }
)
