import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
// import { showLoading, hideLoading } from '../../util/functions';
import { GET_TOP_USER } from './RankingActions'
import RankingReducer from './RankingReducer'

const getTopUser = (dispatch) => async (mainTab, subTab, sortType) => {
	return serverApi
		// .get(appConfig.path_ranking + `?main_tab=${mainTab}&sub_tab=${subTab}`)
		.get(appConfig.path_ranking2 + `?mainTab=${mainTab}&subTab=${subTab}&sortType=${sortType}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_TOP_USER, payload: response.data.data })
				return response
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	RankingReducer,
	{ getTopUser },
	{ dataRanking: [] }
)
