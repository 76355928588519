import React from 'react'
import { css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader'

const override = css`
	position: fixed;
	top: 50%;
	left: calc(50% - 18px);
	opacity: 1;
`

const Loading = () => {
	return (
		<div className={'loading'} id="loading">
			<div className="loading_wrapper"></div>
			<ClipLoader
				css={override}
				size={36}
				color={'#66bb6a'}
				loading={true}
			/>
		</div>
	)
}

export default Loading
