import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import GiftReducer from './GiftReducer'
import { GET_LIST_GIFT } from './GiftActions'

const getListGift = (dispatch) => () => {
	serverApi
		.get(appConfig.path_send_gift)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_LIST_GIFT, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	GiftReducer,
	{ getListGift },
	{ listGift: [] }
)
