import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import StellaAnswerReducer from './StellaAnswerReducer'
import {
	CHECK_FINISH_TUTORIAL,
	GET_ALL_QUESTIONS,
	GET_MYPOST,
	SEARCH_QUESTIONS,
	SEARCH_MYPOST,
	GET_ALL_QUESTIONS_ONCLICK,
	GET_MYPOST_ONCLICK,
	LOADMORE_SEARCH_MYPOST,
	LOADMORE_SEARCH_QUESTIONS,
	GET_SETTING_POINT,
	CHECK_PURCHASED,
	CREATE_QUESTION,
	RESET_LIST_QUESTION,
	GET_ANSWERS,
	LIKE,
	UNLIKE,
	RESET_LIST_ANSWER,
	RESET_PAGE_LIST_ANSWER,
	SET_PAGE_LIST_ANSWER,
	RESET_LIST_MY_POST,
	SELECT_BEST_ANSWER,
	CHECK_FIRST_TIME_CREATE_QUESTION,
	GET_QUESTION_DETAIL,
	OPEN_POPUP_FAVORITE_QUESTION,
	CLOSE_POPUP_FAVORITE_QUESTION,
} from './StellaAnswerAction'

const finishTutorial = (dispatch) => async () => {
	serverApi
		.put(`${appConfig.path_stellaanswer}/finishtutorial`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: CHECK_FINISH_TUTORIAL,
					payload: response.data.data,
				})
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const resetListQuestion = (dispatch) => () => {
	dispatch({ type: RESET_LIST_QUESTION })
}
const resetListMyPost = (dispatch) => () => {
	dispatch({ type: RESET_LIST_MY_POST })
}

const getAllQuestions = (dispatch) => async (data) => {
	const tab = data.tab
	const page = data.page
	const size = data.size
	// showLoading();
	serverApi
		.get(
			`${appConfig.path_stellaanswer}?tab=${tab}&page=${page}&size=${size}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_ALL_QUESTIONS,
					payload: response.data.data,
				})
				// hideLoading();
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const getAllQuestionsOnClick = (dispatch) => async (data) => {
	// showLoading();
	serverApi
		.get(`${appConfig.path_stellaanswer}?tab=${data}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_ALL_QUESTIONS_ONCLICK,
					payload: response.data.data,
				})
				// hideLoading();
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const getMyPost = (dispatch) => async (data) => {
	const tab = data.tab
	const page = data.page
	const size = data.size
	// showLoading();
	await serverApi
		.get(
			`${appConfig.path_stellaanswer}/myquestion?tab=${tab}&page=${page}&size=${size}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_MYPOST, payload: response.data.data })
				// hideLoading();
			}
		})
		.catch((err) => {
			console.error(err)
		})
}
const getMyPostOnClick = (dispatch) => async (data) => {
	// showLoading();
	await serverApi
		.get(`${appConfig.path_stellaanswer}/myquestion?tab=${data}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_MYPOST_ONCLICK,
					payload: response.data.data,
				})
				// hideLoading();
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const searchQuestions = (dispatch) => async (data, activeTab) => {
	// showLoading();
	// sessionStorage.setItem('onSearch', true);

	const tab = `tab=${activeTab}`
	const type = data.type ? `&type=${parseInt(data.type)}` : ''
	const searchKey = data.searchKey ? `&searchKey=${data.searchKey}` : ''
	const sortBy = `&sortBy=${parseInt(data.sortBy)}`
	const category = data.category
		? `&category=${data.category.split(',').map((item) => parseInt(item))}`
		: ''
	const isMyPostTab = activeTab === 'all'
	const myPostTab = isMyPostTab ? '/myquestion' : ''

	const typeDispatch = isMyPostTab ? SEARCH_MYPOST : SEARCH_QUESTIONS

	serverApi
		.get(
			`${appConfig.path_stellaanswer}${myPostTab}?${tab}${type}${searchKey}${sortBy}${category}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: typeDispatch, payload: response.data.data })
				// hideLoading();
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const loadMoreSearchQuestion = (dispatch) => async (data) => {
	sessionStorage.setItem('onSearch', true)

	const tab = `tab=${data.activeTab}`
	const type = data.paramsSearch.type
		? `&type=${parseInt(data.paramsSearch.type)}`
		: ''
	const searchKey = data.paramsSearch.searchKey
		? `&searchKey=${data.paramsSearch.searchKey}`
		: ''
	const sortBy = `&sortBy=${parseInt(data.paramsSearch.sortBy)}`
	const category = data.paramsSearch.category
		? `&category=${data.paramsSearch.category
				.split(',')
				.map((item) => parseInt(item))}`
		: ''
	const isMyPostTab = data.activeTab === 'all'
	const myPostTab = isMyPostTab ? '/myquestion' : ''
	const page = `&page=${data.page}`
	const size = `&size=${data.size}`
	const typeDispatch = isMyPostTab
		? LOADMORE_SEARCH_MYPOST
		: LOADMORE_SEARCH_QUESTIONS

	serverApi
		.get(
			`${appConfig.path_stellaanswer}${myPostTab}?${tab}${type}${searchKey}${sortBy}${category}${page}${size}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: typeDispatch, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const getSettingPoint = (dispatch) => () => {
	serverApi
		.get(appConfig.path_setting_point_stella_answer)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_SETTING_POINT,
					payload: response.data.data,
				})
			}
		})
}

const checkFirstPurchase = (dispatch) => (userId) => {
	serverApi
		.get(`${appConfig.path_check_first_purchase}?userId=${userId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: CHECK_PURCHASED, payload: response.data.data })
			}
		})
}

const getAnswers =
	(dispatch) =>
	({ questionId, page, size }) => {
		serverApi
			.get(
				`${appConfig.path_answer_list}/answer?questionId=${questionId}&page=${page}&size=${size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({ type: GET_ANSWERS, payload: response.data.data })
				}
			})
			.catch((err) => console.log(err))
	}

const resetListAnswer = (dispatch) => () => {
	dispatch({ type: RESET_LIST_ANSWER })
}

const createQuestion = (dispatch) => (data) => {
	const { nickName, isShowMyProfile, content, category } = data
	const params = {
		deviceId: '123321', // need gen deviceId
		nickName,
		isShowMyProfile: isShowMyProfile === 'true',
		content,
		category: +category,
	}
	serverApi
		.post(appConfig.path_stellaanswer, params)
		.then((response) => {
			if (response.data.code === 0) {
				sessionStorage.setItem(
					'createdQuestion',
					JSON.stringify(response.data.data)
				)
				dispatch({ type: CREATE_QUESTION, payload: response.data.data })
			}
		})
		.catch((err) => console.error(err))
}

const like = (dispatch) => (answerId) => {
	serverApi
		.post(`${appConfig.path_stellaanswer}/like?answerId=${answerId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: LIKE, payload: response.data.data })
			}
		})
		.catch((err) => console.error(err))
}

const unlike = (dispatch) => (answerId) => {
	serverApi
		.delete(`${appConfig.path_stellaanswer}/like?answerId=${answerId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: UNLIKE, payload: response.data.data })
			}
		})
		.catch((err) => console.error(err))
}

const selectBestAnswer = (dispatch) => (questionId, answerId) => {
	serverApi
		.put(
			`${appConfig.path_stellaanswer}/bestanswer?questionId=${questionId}&answerId=${answerId}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: SELECT_BEST_ANSWER,
					payload: response.data.data,
					questionId,
					answerId,
				})
			}
		})
		.catch((err) => console.error(err))
}

const resetPageListAnswer = (dispatch) => () => {
	dispatch({ type: RESET_PAGE_LIST_ANSWER })
}
const setPageListAnswer = (dispatch) => (page) => {
	dispatch({ type: SET_PAGE_LIST_ANSWER, payload: page })
}

const favoriteQuestion = (dispatch) => (questionId) => {
	serverApi
		.post(
			`${appConfig.path_stellaanswer}/favorite?questionId=${questionId}`
		)
		.catch((err) => console.error(err))
}

const unfavoriteQuestion = (dispatch) => (questionId) => {
	serverApi
		.delete(
			`${appConfig.path_stellaanswer}/favorite?questionId=${questionId}`
		)
		.catch((err) => console.error(err))
}

const openPopupFavoriteQuestion = (dispatch) => (questionId) => {
	dispatch({ type: OPEN_POPUP_FAVORITE_QUESTION, payload: questionId })
}

const closePopupFavoriteQuestion = (dispatch) => () => {
	dispatch({ type: CLOSE_POPUP_FAVORITE_QUESTION })
}

const getQuestionDetail = (dispatch) => (questionId) => {
	return serverApi
		.get(`${appConfig.path_stellaanswer}/${questionId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_QUESTION_DETAIL,
					payload: response.data.data,
				})
			}
			return response
		})
}

const checkFirstTimeCreateQuestion = (dispatch) => (deviceId) => {
	return serverApi
		.get(
			`${appConfig.path_stellaanswer}/devicequestion?deviceId=${deviceId}`
		)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: CHECK_FIRST_TIME_CREATE_QUESTION,
					payload: response.data.data,
				})
			}
		})
}

export const { Provider, Context, actions } = createDataContext(
	StellaAnswerReducer,
	{
		checkFirstTimeCreateQuestion,
		finishTutorial,
		resetListQuestion,
		resetListMyPost,
		getAnswers,
		getAllQuestions,
		getMyPost,
		searchQuestions,
		getAllQuestionsOnClick,
		getMyPostOnClick,
		loadMoreSearchQuestion,
		getSettingPoint,
		checkFirstPurchase,
		createQuestion,
		like,
		unlike,
		resetListAnswer,
		resetPageListAnswer,
		setPageListAnswer,
		selectBestAnswer,
		favoriteQuestion,
		unfavoriteQuestion,
		closePopupFavoriteQuestion,
		openPopupFavoriteQuestion,
		getQuestionDetail,
	},
	{
		dataFinishTutorial: {},
		questionList: { totalRecord: 0, list: [], hasMore: true },
		myPostList: { list: [], hasMore: true },
		settingPoint: null,
		isNotPurchased: null,
		createdQuestion: [],
		answerList: { totalRecord: 0, list: [], hasMore: true },
		totalLike: null,
		pageListAnswer: 0,
		bestAnswer: {},
		favoriteQuestion: {
			isOpenPoppuFavoriteQuestion: false,
			questionId: null,
		},
		dataQuestionDetail: {},
		dataCheckFirstTimeCreateQuestion: {
			isFirstTimeCreateQuestion: null,
			maximumCharacterQuestion: null,
		},
	}
)
