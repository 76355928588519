import { appConfig } from '../../constants/generalConfig'
import serverApi from '../serverApi'

export const MyPageAPI = (...params) => {
	const deleteProfilePartner = ({ userId, partner }) => {
		return serverApi.put(
			`${appConfig.path_update_user_info}${userId}/${partner}/reset`
		)
	}

	return {
		deleteProfilePartner,
	}
}
