import {
	GET_CHAT_HISTORY,
	GET_MESSAGE_LIST,
	ADD_NEW_MESSAGE,
	REQUEST_CHAT,
	GET_FAVORITE_LIST,
	REAL_TIME_MESSAGE,
	CANCEL_APPOINTMENT,
	CHECK_INCONVERSATION,
	CLOSE_CHAT,
	GET_RESPONSE_REQUEST_SOCKET,
	CLEAR_DATA_CHAT,
	DELETE_MSG_ERROR,
	RECONNECT_CHAT,
	BENCH_TIME,
	TELLER_READY_CHAT,
	READ_MESSAGE,
	REMOVE_UNFAVORITE_TELLER,
	FAVORITE_TELLER_SUCCESS,
	FAVORITE_TELLER_FAIL,
	FAVORITE_TELLER_CLEAR,
	DATA_LIST_CHAT,
	CHECK_USER_FIRST_CHAT_ONLINE,
} from './ChatActions'
import * as Fetch from '../../util/Fetch'
import { getLastChat } from '../../util/functions'
import {
	LIST_MSG_TYPE_NOT_UPDATE_UNREAD,
	LIST_MSG_TYPE_NOT_UPDATE_LASTCHAT,
} from '../../constants/constant'
import { SET_APPOINTMENT } from '../../constants/pathName'
import { actions } from './ChatContext'

const userId = Fetch.getUserId('userId')
const getTellerId = (data) => (data?.toId === userId ? data.fromId : data?.toId)

const getTellerHaveNewMessage = (listChatHistory, tellerId) => {
	return listChatHistory.find((teller) => {
		const tellerHaveNewMessageID = getTellerId(teller)
		return tellerHaveNewMessageID === tellerId
	})
}

const getTellerHaveNoNewMessage = (listChatHistory, tellerId) => {
	return listChatHistory.filter((teller) => {
		const tellerHaveNewMessageID = getTellerId(teller)
		return tellerHaveNewMessageID !== tellerId
	})
}

const handleUnread = (tellerHaveNewMessage, message) => {
	if (!LIST_MSG_TYPE_NOT_UPDATE_UNREAD.includes(message.msgType)) {
		tellerHaveNewMessage.unreadMessageBadge += 1
	} else {
		tellerHaveNewMessage.unreadMessageBadge = 0
	}
}

const handleLastChat = (tellerHaveNewMessage, message) => {
	if (!LIST_MSG_TYPE_NOT_UPDATE_LASTCHAT.includes(message.msgType)) {
		tellerHaveNewMessage.value = getLastChat(message)
	}
}

const updateInformationNewMessage = (tellerHaveNewMessage, message) => {
	if (tellerHaveNewMessage) {
		tellerHaveNewMessage.fromId = message.fromId
		tellerHaveNewMessage.toId = message.toId
		tellerHaveNewMessage.msgId = message.msgId
		tellerHaveNewMessage.msgType = message.msgType
		tellerHaveNewMessage.serverTime = message.serverTime
		handleUnread(tellerHaveNewMessage, message)
		handleLastChat(tellerHaveNewMessage, message)
	}
}

const chatReducer = (state, action) => {
	switch (action.type) {
		case GET_CHAT_HISTORY:
			return {
				...state,
				chatHistory: {
					data: !state.chatHistory.data.includes(undefined) ? state.chatHistory.data.concat(action.payload) : action.payload,
					hasMore: action.payload?.length > 0,
					loaded: true,
				},
			}
		case DATA_LIST_CHAT:
			return {
				...state,
				chatHistory: {
					data: action.payload,
					hasMore: action.payload?.length > 0,
					loaded: true,
				},
			}
		case GET_MESSAGE_LIST:
			return {
				...state,
				messageList: {
					data: action.payload.concat(state.messageList.data),
					hasMore: action.payload.length > 0,
					newMessage: false,
				},
			}
		case GET_FAVORITE_LIST:
			return {
				...state,
				favoriteList: {
					data: action.payload.concat(state.favoriteList.data),
					hasMore: action.payload.length > 0,
				},
			}
		case ADD_NEW_MESSAGE:
			return {
				...state,
				messageList: {
					data: state.messageList.data.concat(action.payload),
				},
				newMessage: true,
			}
		case REQUEST_CHAT:
			return { ...state, responseRequestChat: action.payload }
		case CANCEL_APPOINTMENT:
			return { ...state, appointment: action.payload }
		case SET_APPOINTMENT:
			return { ...state, appointment: action.payload }
		case REAL_TIME_MESSAGE:
			const message = action.payload.message
			const tellerId = getTellerId(message)
			const tellerHaveNewMessage = getTellerHaveNewMessage(
				state.chatHistory.data,
				tellerId
			)
			if (tellerHaveNewMessage !== undefined) {
				updateInformationNewMessage(tellerHaveNewMessage, message)
				const tellerHaveNoNewMessage = getTellerHaveNoNewMessage(
					state.chatHistory.data,
					tellerId
				)
				const newListChatHistory = [
					tellerHaveNewMessage,
					...tellerHaveNoNewMessage,
				]
				return {
					...state,
					chatHistory: { ...state.chatHistory, data: newListChatHistory },
				}
			} else {
				actions.getListChat({ page: 0, size: 24, tab: action.payload.tab })
			}
		case CHECK_INCONVERSATION:
			return { ...state, statusChat: action.payload }
		case CLOSE_CHAT:
			return { ...state, closeChat: action.payload }
		case GET_RESPONSE_REQUEST_SOCKET:
			return { ...state, responseRequestSocket: action.payload }
		case RECONNECT_CHAT:
			return { ...state, reconnect: action.payload }

		case BENCH_TIME:
			return { ...state, benchTime: action.payload }
		case TELLER_READY_CHAT:
			return { ...state, checkReadyChat: action.payload }
		case CLEAR_DATA_CHAT:
			return {
				chatHistory: { data: [], hasMore: false },
				messageList: { data: [], hasMore: false },
				favoriteList: { data: [], hasMore: false },
				responseRequestChat: {},
				favorited: null,
				appointment: '',
				statusChat: {},
				closeChat: {},
				responseRequestSocket: {},
				dataReadMsg: [],
			}
		case DELETE_MSG_ERROR:
			const dataChat = [...state.messageList.data]
			dataChat[action.payload].isDelete = true
			const messageList = state.messageList
			messageList.data = dataChat
			return { ...state, messageList }
		case READ_MESSAGE:
			const dataReadMsgId = [...state.dataReadMsg]
			dataReadMsgId.push(action.payload.value)
			return { ...state, dataReadMsg: dataReadMsgId }
		case REMOVE_UNFAVORITE_TELLER:
			const chatHistory = [...state.chatHistory.data]
			const result = chatHistory.filter(
				(item) => item.msgId !== action.payload.msgId
			)
			return {
				...state,
				chatHistory: {
					...state.chatHistory,
					data: result,
				},
			}
		case FAVORITE_TELLER_SUCCESS:
			return {
				...state,
				favoriteTellerData: {
					code: action.payload.code,
					status: 'success',
				},
			}
		case FAVORITE_TELLER_FAIL:
			return {
				...state,
				favoriteTellerData: {
					code: action.payload.code,
					status: 'failed',
				},
			}
		case FAVORITE_TELLER_CLEAR:
			return {
				...state,
				favoriteTellerData: { code: null, status: null },
			}
		case CHECK_USER_FIRST_CHAT_ONLINE:
			return {
				...state,
				isUserFirstTimeChatOnline: action.payload,
			}
		default:
			return state
	}
}

export default chatReducer
