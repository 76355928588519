import jwtdecode from 'jwt-decode'

export function getUserId() {
	const token = localStorage.getItem('token')
	if (!token) {
		return ''
	}
	const decoded = jwtdecode(token)
	return decoded.userId
}

export function getToken() {
	return localStorage.getItem('token')
}

export function setToken(token) {
	return localStorage.setItem('token', token)
}

export function applicationId() {
	const token = localStorage.getItem('token')
	if (!token) {
		return ''
	}
	const decoded = jwtdecode(token)
	return decoded.usingApplication
}

export function setExpireToken(token) {
	const decoded = jwtdecode(token)
	const date = new Date()
	const expiredRemainTime = decoded.expiredRemainTime + date.getTime()
	localStorage.setItem('expiredRemainTime', expiredRemainTime)
}

export function checkExpireToken() {
	const token = localStorage.getItem('token')
	if (!token) {
		return false
	}
	const expiredRemainTime = localStorage.getItem('expiredRemainTime')
	if (!expiredRemainTime) {
		return false
	}
	// const date = new Date()
	// if (expiredRemainTime < date.getTime()) {
	//     return false
	// }
	return true
}

export function fetchUserAgent() {
	const userAgent = navigator.userAgent.toLowerCase()
	if (userAgent.indexOf('iphone') !== -1) return 1

	if (userAgent.indexOf('android') !== -1) return 2

	return 3
}

export function getDeviceName() {
	const userAgent = navigator.userAgent.toLowerCase()
	if (userAgent.indexOf('iphone') !== -1) return 'Mac'

	if (userAgent.indexOf('android') !== -1) return 'Android'

	return 'Web'
}

export function rememberEmailPassword(password, email) {
	localStorage.setItem('_info', `${password}${btoa(email)}`)
}

export function clearStorage() {
	localStorage.removeItem('expiredRemainTime')
	localStorage.removeItem('isFinishTutorial')
	localStorage.removeItem('constellationType')
	localStorage.removeItem('loadFavoriteList')
	localStorage.removeItem('userId')
	localStorage.removeItem('constellationSign')
	localStorage.removeItem('user')
	localStorage.removeItem('currentPoint')
	localStorage.removeItem('token')
	localStorage.removeItem('close_news')
	localStorage.removeItem('numberUnreadNotification')
	localStorage.removeItem('requestId')
	localStorage.removeItem('isShowPopupUpdateProfile')
	localStorage.removeItem('messageError')
}
