const InitConfig = () => {
	if (process.env.REACT_APP_ENV === 'prod') {
		return {
			api_base_url: 'https://api.stella-app.com',
			ws_base_url: 'https://ws.stella-app.com',
			web_api_url: 'https://api2.stella-app.com',
			image_base_url: 'https://picture.stella-app.com/',
			webview_url: 'https://webview.stella-app.com',
			webview_birthday: 'https://webview.stella-app.com/event?act=birthday'
		}
		//http://apimy.dev.stella-app.com/ums/v1/usersessions
	} else {
		return {
			// api_base_url: "http://210.148.155.91:8080",
			api_base_url: 'https://api.dev.stella-app.com',
			// api_2_base_url: "https://apimy.dev.stella-app.com",
			ws_base_url: 'https://ws.dev.stella-app.com',
			web_api_url: 'https://api2.stella-app.com',
			image_base_url: 'https://picture.dev.stella-app.com/',
			webview_url: 'https://webview.dev.stella-app.com',
			webview_birthday: 'https://webview.dev.stella-app.com/event?act=birthday'
		}
	}
}
const initConfig = InitConfig()

export const appConfig = {
	api_base_url: initConfig.api_base_url,
	// api_2_base_url: initConfig.api_2_base_url,
	ws_base_url: initConfig.ws_base_url,
	web_api_url: initConfig.web_api_url,
	image_base_url: initConfig.image_base_url,
	webview_base_url: initConfig.webview_url,
	webview_base_birthday: initConfig.webview_birthday,
	path_login: '/ums/v1/usersessions',
	path_logout: '/ums/v1/users',
	path_get_user: '/ums/v1/users',
	fortuner_teller: '/ums/v1/fortunetellers',
	fortuner_teller_posts: '/buzz/v1/posts',
	path_ranking: '/ums/v1/ranking',
	path_ranking2: '/ums/v2/rankings',
	path_ranking_top: '/ums/v1/ranking/top3',
	path_teller_list: '/ums/v1/fortunetellers',
	path_partners: '/ums/v1/users/',
	path_banners: '/ums/v1/banners',
	path_banners_mypage: '/ums/v1/banners/mypage',
	path_reviewmode_setting: '/ums/v1/users/reviewmodesetting',
	path_update_user_info: '/ums/v1/users/',
	path_reviews: '/ums/v1/reviews',
	path_news: '/ums/v1/news',
	path_wallpaper: '/ums/v1/wallpapers',
	path_stellaanswer: '/ums/v1/stellaanswer',
	path_block: '/ums/v1/blocks',
	path_campaigncode: '/ums/v1/campaigncodes',
	path_answer_list: '/ums/v1/stellaanswer',
	path_timeline_list: '/buzz/v1/posts',
	path_like_post: '/buzz/v1/likes',
	path_request_chat: '/ums/v1/requests',
	path_expected_request: '/ums/v1/requests/expectrequest',
	path_chat_history: '/chat/v1/lastchats',
	path_chat_message_list: '/chat/v1/histories',
	path_teller_info: '/ums/v1/fortunetellers',
	path_favorite: '/ums/v1/favorites',
	path_send_gift: 'ums/v1/pointgifts',
	path_get_notification: '/jpns/v1/notifications',
	path_get_news: 'ums/v1/news',
	path_comment_list: '/buzz/v1/comments',
	path_subcomment_list: './buzz/v1/subcomments',
	path_favorite_message: 'chat/v1/favoritemessage',
	path_setting_point_stella_answer: 'ums/v1/stellaanswer/answersetting',
	path_check_first_purchase: 'ums/v1/transactions/checkuserpurchase',
	path_reviews_teller: '/ums/v1/reviews',
	path_verify_code: '/ums/v1/userverifycodes',
	path_upload_file: '/static/v1/files',
	path_prepare_send_file: 'chat/v1/files',
	path_report_user: 'ums/v1/reports',
	path_check_inconversation: 'ums/v1/requests/inconversationstatus',
	path_close_chat: '/ums/v1/requests/',
	path_get_teller_register: '/ums/v1/recommends/tellerintroduction',
	path_get_teller_introduction: '/ums/v1/recommends/telleractive',
	path_get_teller_review: '/ums/v1/recommends/reviews',
	path_check_point_unlock: '/chat/v1/histories/checkpoint',
	path_unlock_message: '/chat/v1/histories/show',
	path_reconnect_chat: 'ums/v1/requests/reconnect',
	path_bench_time: 'ums/v1/requests/checkpendingchat',
	path_ready_chat: '/ums/v1/fortunetellers',
	path_refresh_token: '/ums/v1/token',
	path_verify_phone_number: 'ums/v1/phoneverification',
}

export const keyIdLogin = {
	appIdFB:
		process.env.REACT_APP_ENV === 'prod'
			? '1727739773937893'
			: '943965409406548',
	clientIdGG:
		process.env.REACT_APP_ENV === 'prod'
			? '319979049056-5e60c8ovi586fjmcfpb1g365dei4rsl4.apps.googleusercontent.com'
			: '703337868272-71268fr73162cq00b0o0825lorabbce9.apps.googleusercontent.com',
}
export const webApiKey = 'AIzaSyApYXE3zEkMoM_GH-vx9BY6xxxnh1QlpG4'
export const dynamicLinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${webApiKey}`
export const domainUriPrefix = 'https://appstella.page.link'
export const androidPackageName = 'com.app.stella'
export const iosBundleId = 'cpm.app.stella'
export const stellaAppUrl =
	'https://itunes.apple.com/jp/app/line/id1315482422?mt=8'
