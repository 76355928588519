export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const HOME = '/'
export const ABOUT_PRICE = '/about-price'
export const INTRODUCE = '/introduce'
export const INTRODUCE_PERSON = '/introduce/person'
export const ACCOUNT_TRANSFER = '/account-transfer'
export const HELP = '/webview/help'
export const NOTIFICATION = '/notification'
export const TELLER_LIST = '/teller-list'
export const FAVORITE_LIST = '/favorite-list'
export const RANKING = '/ranking'
export const FREE_POINT = '/webview/free-point'
export const BLOG = '/webview/blog'
export const BLOG_LIST = '/blog-list'
export const ANSWER = '/answer'
export const ANSWER_CREATE = '/answer/create'
export const ANSWER_DETAIL = '/answer/detail/:id'
export const CHAT = '/chat'
export const CHAT_DETAIL = '/chat/detail/:id'
export const GIFT = '/send-gift/:id'
export const SET_APPOINTMENT = '/set-expected-date/:id'
export const MY_PAGE = '/my-page'
export const EDIT_PROFILE = '/my-page/edit-profile'
export const BLOCK_LIST = '/block-list'
export const CAMPAIGN_CODE = '/campaign-code'
export const TELLER_PROFILE = '/teller-profile'
export const REVIEW = '/teller-profile/review'
export const TELLER_PROFILE_TIMELINE = '/teller-profile/timeline'
export const TIMELINE = '/timeline'
export const HOW_TO_USE = '/webview/how-to-use'
export const WALLPAPER = '/wallpaper'
export const DOWNLOAD_WALLPAPER = '/wallpaper/download/:id'
export const DEACTIVE = '/deactive'
export const LOGOUT = '/deactive/logout'
export const WEBVIEW = '/webview'
export const TERMS = '/webview/terms'
export const PRIVACY = '/webview/privacy'
export const WEBVIEW_LINK = '/webview/link'
export const BUY_POINT = '/webview/buy-point'
export const POINT = '/webview/purchase'
export const TERM_OF_SERVICE = '/webview/notice'
export const WEB_3RD_YEAR = '/webview/3rd-year'
export const NOT_FOUND = '*'
export const VERIFY_TO_GET_POINT = '/verify'
export const VERIFY_SMS = '/verify/sms'
export const WEB_BIRTHDAY_YEAR = '/webview/birthday'
