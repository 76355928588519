export const NOTICE_TYPE = {
	NONE: 0,
	ONLINE_ALERT: 2,
	DAILY_BONUS: 3,
	FAVORITE_POST_TIMELINE: 4,
	APPROVED_POST: 5,
	DENIED_POST: 6,
	APPROVED_COMMENT: 7,
	DENIED_COMMENT: 8,
	APPROVE_SUB_COMMENT: 9,
	DENIED_SUB_COMMENT: 10,
	APPROVED_USER_INFO: 11,
	DENIED_PART_USER_INFO: 12,
	DENIED_USER_INFO: 13,
	AUTO_NOTIFICATION: 16,
	REGISTER_NEW_DEVICE: 26,
	LOGIN_BONUS: 29,
	APPROVE_USER_QUESTION: 31,
	DENIED_USER_QUESTION: 32,
	TELLER_ANSWER_USER_QUESTION: 33,
	ANSWER_TIME_EXPIRED: 34,
	PAGE_SUPPORT: 41,
	QUESTION_HAS_ANSWER: 43,
	QUESTION_TIME_EXPIRED: 44,
	QUESTION_BEST_ANSWER: 45,
	RECEIVE_POINT: 46,
	INTRODUCE_USER: 48,
	BENCH_TIME_CHAT: 50,
	BONUS_POINT_REGULAR: 53,
}

export const TYPE_SYSTEM = 1
export const TYPE_BACKEND = 2
export const TYPE_STELLA_ANSWER = 3
