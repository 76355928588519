import { createSlice } from '@reduxjs/toolkit'
import { SAGAS_CONSTANTS } from '../../../sagas/sagasConstants'

const initialState = {
	data: {},
	status: SAGAS_CONSTANTS.STATUS.IDLE,
}

const MyPageSlice = createSlice({
	name: 'MyPage',
	initialState,
	reducers: {
		doDeleteProfilePartner: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.LOADING
		},
		deleteProfilePartnerSuccess: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.SUCCESS
			state.data = action.payload
		},
		deleteProfilePartnerFail: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.FAILURE
			state.data = action.payload
		},
		resetStatus: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.IDLE
		},
	},
})

export const {
	doDeleteProfilePartner,
	deleteProfilePartnerSuccess,
	deleteProfilePartnerFail,
	resetStatus,
} = MyPageSlice.actions

export const MyPageSelector = (state) => state.MyPage

export default MyPageSlice.reducer
