import { GET_LIST_FAVORITE } from './FavoriteListActions'

const FavoriteListReducer = (state, action) => {
	switch (action.type) {
		case GET_LIST_FAVORITE:
			return { ...state, favoriteList: action.payload }
		default:
			return state
	}
}

export default FavoriteListReducer
