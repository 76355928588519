import * as moment from 'moment'

export default class Comment {
	constructor(attributes) {
		const comment = Object.assign({}, attributes || {})
		const user = JSON.parse(localStorage.getItem('user'))

		comment.accountDeactive = true
		comment.birthday = user.birthday
		comment.createdTime = moment().utc().format('YYYYMMDDHHmm')
		comment.gender = user.gender
		comment.subcommentNumber = 0
		comment.subcomments = []
		comment.userId = user.userId
		comment.userName = user.userName
		comment.userType = 1

		Object.keys(comment).forEach((key) => {
			this[key] = comment[key]
		})
	}
}

export class SubComment {
	constructor(attributes) {
		const subComment = Object.assign({}, attributes || {})
		const user = JSON.parse(localStorage.getItem('user'))

		subComment.accountDeactive = true
		subComment.birthday = user.birthday
		subComment.createdTime = moment().utc().format('YYYYMMDDHHmm')
		subComment.gender = user.gender
		subComment.userId = user.userId
		subComment.userName = user.userName
		subComment.userType = 1

		Object.keys(subComment).forEach((key) => {
			this[key] = subComment[key]
		})
	}
}
