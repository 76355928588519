import { ADD_ITEM, REMOVE_ITEM, REMOVE_ALL } from './FavoriteMessageActions'

const FavoriteMessageReducer = (state, action) => {
	switch (action.type) {
		case ADD_ITEM:
			const listAdd = [...state.listFavoriteMessage]
			return {
				...state,
				listFavoriteMessage: [...listAdd, action.payload],
			}
		case REMOVE_ITEM:
			const listRemove = [...state.listFavoriteMessage]
			let result
			if (action.payload.type === 'loadFavoriteList') {
				result = listRemove.filter(
					(item) => item !== action.payload.msgId
				)
			} else {
				result = listRemove.filter(
					(item) => item.msgId !== action.payload.msgId
				)
			}
			return { ...state, listFavoriteMessage: [...result] }
		case REMOVE_ALL:
			return { ...state, listFavoriteMessage: [] }
		default:
			return state
	}
}

export default FavoriteMessageReducer
