import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import { GET_TELLER_INFO } from './TellerInfoActions'
import TellerInfoReducer from './TellerInfoReducer'

const getTellerInfo = (dispatch) => (tellerId) => {
	serverApi
		.get(appConfig.path_teller_info + `/${tellerId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_TELLER_INFO, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	TellerInfoReducer,
	{ getTellerInfo },
	{ tellerInfo: {} }
)
