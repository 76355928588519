import { createSlice } from '@reduxjs/toolkit'
import { SAGAS_CONSTANTS } from '../../../sagas/sagasConstants'

const initialState = {
	isShow: false,
	dialogInviteToPurchase: { isOpen: false, isPurchased: true },
	dialogInviteToReviewTeller: { isOpen: false, tellerId: '' },
	status: SAGAS_CONSTANTS.STATUS.IDLE,
}

const GlobalScreenSlice = createSlice({
	name: 'GlobalScreen',
	initialState,
	reducers: {
		showDialogUserIsBlocked: (state, action) => {
			state.isShow = true
		},
		hideDialogUserIsBlocked: (state, action) => {
			state.isShow = false
		},
		showInviteToPurchase: (state, action) => {
			state.dialogInviteToPurchase.isOpen = true
			state.dialogInviteToPurchase.isPurchased = false
		},
		hideInviteToPurchase: (state, action) => {
			state.dialogInviteToPurchase.isOpen = false
		},
		showInviteToReviewTeller: (state, action) => {
			state.dialogInviteToReviewTeller.isOpen = true
			state.dialogInviteToReviewTeller.tellerId = action.payload.tellerId
		},
		hideInviteToReviewTeller: (state, action) => {
			state.dialogInviteToReviewTeller.isOpen = false
			state.dialogInviteToReviewTeller.tellerId = ''
		},
	},
})

export const {
	showDialogUserIsBlocked,
	hideDialogUserIsBlocked,
	showInviteToPurchase,
	hideInviteToPurchase,
	showInviteToReviewTeller,
	hideInviteToReviewTeller,
} = GlobalScreenSlice.actions

export const GlobalScreenSelector = (state) => state.GlobalScreen

export default GlobalScreenSlice.reducer
