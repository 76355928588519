import {
	GET_ALL_QUESTIONS,
	CHECK_FINISH_TUTORIAL,
	GET_MYPOST,
	SEARCH_QUESTIONS,
	GET_ALL_QUESTIONS_ONCLICK,
	GET_MYPOST_ONCLICK,
	SEARCH_MYPOST,
	LOADMORE_SEARCH_MYPOST,
	LOADMORE_SEARCH_QUESTIONS,
	GET_SETTING_POINT,
	CHECK_PURCHASED,
	CREATE_QUESTION,
	RESET_LIST_QUESTION,
	GET_ANSWERS,
	LIKE,
	UNLIKE,
	RESET_LIST_ANSWER,
	RESET_PAGE_LIST_ANSWER,
	SET_PAGE_LIST_ANSWER,
	RESET_LIST_MY_POST,
	SELECT_BEST_ANSWER,
	OPEN_POPUP_FAVORITE_QUESTION,
	CLOSE_POPUP_FAVORITE_QUESTION,
	CHECK_FIRST_TIME_CREATE_QUESTION,
	GET_QUESTION_DETAIL,
} from './StellaAnswerAction'

const StellaAnswerReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_QUESTIONS:
			return {
				...state,
				questionList: {
					totalRecord: action.payload.totalRecord,
					list: action.payload.list,
					hasMore: action.payload.list?.length > 0,
				},
			}
		case SEARCH_MYPOST:
			return {
				...state,
				myPostList: {
					list: state.myPostList.list.concat(action.payload),
					hasMore: action.payload?.length > 0,
				},
			}
		case LOADMORE_SEARCH_QUESTIONS:
			return {
				...state,
				questionList: {
					totalRecord: action.payload.totalRecord,
					list: state.questionList.list.concat(action.payload.list),
					hasMore: action.payload.list?.length > 0,
				},
			}
		case LOADMORE_SEARCH_MYPOST:
			return {
				...state,
				myPostList: {
					list: state.myPostList.list.concat(action.payload),
					hasMore: action.payload?.length > 0,
				},
			}
		case GET_ALL_QUESTIONS:
			return {
				...state,
				questionList: {
					totalRecord: action.payload.totalRecord,
					list: state.questionList.list.concat(action.payload.list),
					hasMore: action.payload.list?.length > 0,
				},
			}
		case GET_ALL_QUESTIONS_ONCLICK:
			return {
				...state,
				questionList: {
					totalRecord: action.payload.totalRecord,
					list: state.questionList.list.concat(action.payload.list),
					hasMore: action.payload.list?.length > 0,
				},
			}
		case GET_MYPOST:
			return {
				...state,
				myPostList: {
					list: state.myPostList.list.concat(action.payload),
					hasMore: action.payload?.length > 0,
				},
			}
		case GET_MYPOST_ONCLICK:
			return {
				...state,
				myPostList: {
					list: state.myPostList.list.concat(action.payload),
					hasMore: action.payload?.length > 0,
				},
			}
		case CHECK_FINISH_TUTORIAL:
			return { ...state, dataFinishTutorial: action.payload }
		case GET_SETTING_POINT:
			return { ...state, settingPoint: action.payload }
		case CHECK_PURCHASED:
			return { ...state, isNotPurchased: action.payload.isFirstTime }
		case CREATE_QUESTION:
			return { ...state, createdQuestion: action.payload }
		case RESET_LIST_QUESTION:
			return {
				...state,
				questionList: { totalRecord: 0, list: [], hasMore: true },
			}
		case RESET_LIST_MY_POST:
			return { ...state, myPostList: { list: [], hasMore: true } }
		case RESET_LIST_ANSWER:
			return {
				...state,
				answerList: { totalRecord: 0, list: [], hasMore: true },
			}
		case RESET_PAGE_LIST_ANSWER:
			return { ...state, pageListAnswer: 0 }
		case SET_PAGE_LIST_ANSWER:
			return { ...state, pageListAnswer: action.payload }
		case GET_ANSWERS:
			return {
				...state,
				answerList: {
					totalRecord: action.payload.totalRecord,
					list: state.answerList.list.concat(action.payload.list),
					hasMore: action.payload.list?.length > 0,
				},
			}
		case LIKE:
			return { ...state, totalLike: action.payload.totalLikeAnswer }
		case UNLIKE:
			return { ...state, totalLike: action.payload.totalLikeAnswer }
		case SELECT_BEST_ANSWER:
			// update best answer local
			const answers = [...state.answerList.list]
			const answerId = action.answerId
			const answerIndex = answers.findIndex(
				(item) => item.answerId === answerId
			)
			answers[answerIndex].isBestAnswer = true
			return {
				...state,
				bestAnswer: action.payload,
				answerList: { ...state.answerList, list: answers },
			}
		case OPEN_POPUP_FAVORITE_QUESTION:
			return {
				...state,
				favoriteQuestion: {
					isOpenPoppuFavoriteQuestion: true,
					questionId: action.payload,
				},
			}
		case CLOSE_POPUP_FAVORITE_QUESTION:
			return {
				...state,
				favoriteQuestion: {
					isOpenPoppuFavoriteQuestion: false,
					questionId: null,
				},
			}
		case CHECK_FIRST_TIME_CREATE_QUESTION:
			return {
				...state,
				dataCheckFirstTimeCreateQuestion: {
					isFirstTimeCreateQuestion:
						action.payload.isFirstTimeCreateQuestion,
					maximumCharacterQuestion:
						action.payload.maximumCharacterQuestion,
				},
			}
		case GET_QUESTION_DETAIL:
			return { ...state, dataQuestionDetail: action.payload }
		default:
			return state
	}
}

export default StellaAnswerReducer
