import createDataContext from '../createDataContext'
import ChangeStateChatDetailReducer from './ChangeStateChatDetailReducer'
import {
	CHANGE_TO_FAVORITE_LIST,
	RESET_FAVORITE_LIST,
} from './ChangeStateChatDetailActions'

const loadFavoriteList = (dispatch) => () => {
	dispatch({ type: CHANGE_TO_FAVORITE_LIST })
}

const resetFavoriteList = (dispatch) => () => {
	dispatch({ type: RESET_FAVORITE_LIST })
}

export const { Provider, Context, actions } = createDataContext(
	ChangeStateChatDetailReducer,
	{ loadFavoriteList, resetFavoriteList },
	{ isLoadFavoriteList: false }
)
