import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {
	HOME,
	LOGIN,
	REGISTER,
	FORGOT_PASSWORD,
	WEBVIEW,
	ABOUT_PRICE,
} from '../constants/pathName'

const PrivateRoute = lazy(() => import('./PrivateRoute'))
const RegisterRoutes = lazy(() => import('./RegisterRoutes'))
const LoginRoutes = lazy(() => import('./LoginRoutes'))
const AuthenticatedRoutes = lazy(() => import('./AuthenticatedRoutes'))
const WebviewRoutes = lazy(() => import('./WebviewRoutes'))
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword/ForgotPassword'))
const AboutPrice = lazy(() => import('../screens/AboutPrice/AboutPrice'))

const RootRoutes = () => {
	return (
		<Suspense fallback={null}>
			<Router>
				<Switch>
					<Route path={REGISTER} component={RegisterRoutes} />
					<Route path={LOGIN} component={LoginRoutes} />
					<Route path={FORGOT_PASSWORD} component={ForgotPassword} />
					<Route path={WEBVIEW} component={WebviewRoutes} />
					<Route path={ABOUT_PRICE} component={AboutPrice} />
					<PrivateRoute path={HOME}>
						<AuthenticatedRoutes />
					</PrivateRoute>
				</Switch>
			</Router>
		</Suspense>
	)
}

export default RootRoutes
