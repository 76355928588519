import React, { useEffect } from 'react'
import RootRoutes from './routes/RootRoutes'
import Loading from './components/Loading'
import { MainProvider } from './MainProvider'
import { ToastContainer } from 'react-toastify'
import Popup from '../src/components/Popup/PopupDefaut'
import { SocketContext, socket } from '../src/socket/socket'
import * as socketConstants from './constants/socket'
import { useDispatch } from 'react-redux'
import { updateCurrentPoint } from './screens/PointController/PointControllerSagas/PointControllerReducer'
import { actions as BuyPointAction } from './contexts/BuyPoint/BuyPointContext'
import BaseController from './BaseController'
import * as Fetch from './util/Fetch'
import moment from 'moment'
function App() {
	const dispatch = useDispatch()

	useEffect(() => {
		handleSocket()

		return () => {
			socket.off(socketConstants.POINT, handlePoint)
		}
	}, [])

	const handleSocket = () => {
		socket.on(socketConstants.POINT, handlePoint)
	}

	const handlePoint = (response) => {
		const message = JSON.parse(response)
		const point = message.curPoint
		console.log('%c  point:', 'color: #0e93e0;background: #aaefe5;', point)

		dispatch({
			type: updateCurrentPoint().type,
			payload: point,
		})

		if (
			point &&
			parseInt(point) < 250 &&
			message.action === 'ACTION_CHAT'
		) {
			BuyPointAction.openBuyPoint()
		}
	}
	useEffect(() => {
		// emit authen message
		const userId = Fetch.getUserId()
		const deviceType = Fetch.fetchUserAgent()

		const message = {
			fromId: userId,
			deviceType,
			msgType: 'AUTHEN',
			value: localStorage.getItem('token'),
			time: moment.utc(420).format('YYYYMMDDhhmmss'),
		}

		// connection listeners
		if (socket.connected) {
			console.log(
				'%c  CONNECTED WITH ID:',
				'color: #0e93e0;background: #aaefe5;',
				socket.id
			)
		}
		socket.on('connect', (response) => {
			socket.emit('authen', JSON.stringify(message))
			if (socket.connected) {
				console.log(
					'%c  CONNECTED WITH ID:',
					'color: #0e93e0;background: #aaefe5;',
					socket.id
				)
			}
		})
		socket.on('disconnect', () => {
			console.log(
				'%c SOCKET IS DISCONNECTED ',
				'color: #0e93e0;background: #aaefe5;'
			)
		})
	}, [])
	// reconnect socket manually
	useEffect(() => {
		document.onvisibilitychange = async (event) => {
			if (window.document.visibilityState === 'visible') {
				console.log(
					'%c  socket.disconnected:',
					'color: #0e93e0;background: #aaefe5;',
					socket.disconnected
				)
				if (socket.disconnected) {
					socket.io.reconnect()
				}
			}
		}
	}, [])
	useEffect(() => {
		window.onfocus = () => {
			if (socket.disconnected) {
				socket.io.reconnect()
			}
		}
	}, [])

	return (
		<>
			<SocketContext.Provider value={socket}>
				<BaseController />
				<MainProvider>
					<RootRoutes />
				</MainProvider>
				<ToastContainer />
				{/* <Loading /> */}
				<Popup />
			</SocketContext.Provider>
		</>
	)
}

export default App
