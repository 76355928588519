import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
// import { showLoading, hideLoading } from '../../util/functions';
import {
	GET_TIMELINE_DETAIL,
	GET_COMMENT_LIST,
	GET_SUBCOMMENT_LIST,
	ADD_COMMENT,
	DELETE_COMMENT,
	ADD_SUB_COMMENT,
	GET_ONLY_COMMENT,
	DELETE_SUB_COMMENT,
} from './TimelineActions'
import TimelineReducer from './TimelineReducer'
import Comment from '../../entities/Comment'
import SubComment from '../../entities/Comment'

const getTimelineDetail = (dispatch) => (id) => {
	// showLoading();
	serverApi
		.get(appConfig.path_timeline_list + `/${id}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_TIMELINE_DETAIL,
					payload: response.data.data,
				})
				// hideLoading();
			}
		})
		.catch((err) => {
			// hideLoading();
			console.error(err)
		})
}

const getCommentDetail =
	(dispatch) =>
	(postId, page, size = 99) => {
		serverApi
			.get(
				appConfig.path_comment_list +
					`?postId=${postId}&page=${page}&size=${size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					if (size === 1) {
						dispatch({
							type: GET_ONLY_COMMENT,
							payload: response.data.data,
						})
					}
					dispatch({
						type: GET_COMMENT_LIST,
						payload: response.data.data,
					})
					// hideLoading();
				}
			})
			.catch((err) => {
				// hideLoading();
				console.error(err)
			})
	}

const getSubcommentList =
	(dispatch) =>
	(cmtId, page, size = 999) => {
		serverApi
			.get(
				appConfig.path_subcomment_list +
					`?commentId=${cmtId}&page=${page}&size=${size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_SUBCOMMENT_LIST,
						payload: response.data.data,
					})
					// hideLoading();
				}
			})
			.catch((err) => {
				// hideLoading();
				console.error(err)
			})
	}

const addComment = (dispatch) => (postId, commentValue) => {
	return serverApi
		.post(appConfig.path_comment_list, { postId, commentValue })
		.then((response) => {
			if (response.data.code === 0) {
				response.data.data.postId = postId
				response.data.data.commentValue = commentValue
				response.data.data.approved = response.data.data.approve
				dispatch({
					type: ADD_COMMENT,
					payload: new Comment(response.data.data),
				})
			}
			return response
		})
}

const deleteComment = (dispatch) => (cmtId, indexCmt) => {
	serverApi
		.delete(appConfig.path_comment_list + `/${cmtId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: DELETE_COMMENT, payload: indexCmt })
			}
		})
		.catch((err) => {
			console.error(err)
		})
}

const addSubComment =
	(dispatch) => (postId, subcommentValue, commentId, indexReplyCmt) => {
		return serverApi
			.post(appConfig.path_subcomment_list, {
				postId,
				subcommentValue,
				commentId,
			})
			.then((response) => {
				if (response.data.code === 0) {
					response.data.data.approved = response.data.data.approve
					response.data.data.postId = postId
					response.data.data.subcommentValue = subcommentValue
					response.data.data.commentId = commentId

					dispatch({
						type: ADD_SUB_COMMENT,
						payload: new SubComment(response.data.data),
						indexReplyCmt,
					})
				}
				return response
			})
			.catch((err) => {
				console.error(err)
			})
	}

const deleteSubComment =
	(dispatch) => (subcommentId, indexSubCmt, indexReplyCmt) => {
		serverApi
			.delete(appConfig.path_subcomment_list + `/${subcommentId}`)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: DELETE_SUB_COMMENT,
						indexReplyCmt,
						indexSubCmt,
					})
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

export const { Provider, Context, actions } = createDataContext(
	TimelineReducer,
	{
		getTimelineDetail,
		getCommentDetail,
		getSubcommentList,
		addComment,
		deleteComment,
		addSubComment,
		deleteSubComment,
	},
	{
		TimelineDetail: [],
		CommentList: [],
		SubCommentList: [],
		isAddSubCmt: false,
	}
)
