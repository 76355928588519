import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import {
	TELLER_LIST,
	TELLER_LIST_DEFAULT,
	TELLER_LIST_DEFAULT_HOME,
	TELLER_LIST_HOME,
} from './TellerListActions'
import TellerListReducer from './TellerListReducer'
import { showLoading, hideLoading } from '../../util/functions'

const defaultList = (dispatch) => () => {
	dispatch({ type: TELLER_LIST_DEFAULT })
}

const tellerList =
	(dispatch) =>
		(params = {}) => {
			// showLoading();
			let stringUrl = '?' + params.join('&')
			serverApi
				.get(appConfig.fortuner_teller + stringUrl, {})
				.then((response) => {
					console.log('tellerList', response)
					if (response.data.code === 0) {
						// hideLoading();
						dispatch({ type: TELLER_LIST, payload: response.data.data })
					}
					return response
				})
				.catch((err) => {
					dispatch({
						type: TELLER_LIST,
						payload: err.response,
					})
				})
		}
const defaultListHome = (dispatch) => () => {
	dispatch({ type: TELLER_LIST_DEFAULT_HOME })
}

const tellerListHome =
	(dispatch) =>
		(params = {}) => {
			// showLoading();
			let stringUrl = '?' + params.join('&')
			serverApi
				.get(appConfig.fortuner_teller + stringUrl, {})
				.then((response) => {
					console.log('tellerListHome', response)
					if (response.data.code === 0) {
						// hideLoading();
						dispatch({
							type: TELLER_LIST_HOME,
							payload: response.data.data,
						})
					}
				})
				.catch((err) => {
					console.log('%c err', 'color: red', err)
				})
		}

export const { Provider, Context, actions } = createDataContext(
	TellerListReducer,
	{ tellerList, defaultList, defaultListHome, tellerListHome },
	{
		listTeller: { data: [], isLoaded: false, hasMore: false },
		listTellerHome: { data: null, isLoaded: false, hasMore: false },
	}
)
