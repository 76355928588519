import { createSlice } from '@reduxjs/toolkit'
import { SAGAS_CONSTANTS } from '../../../sagas/sagasConstants'

const initialState = {
	data: {
		currentPoint: 0,
	},
	status: SAGAS_CONSTANTS.STATUS.IDLE,
}

const PointControllerSlice = createSlice({
	name: 'PointController',
	initialState,
	reducers: {
		updateCurrentPoint: (state, action) => {
			state.data.currentPoint = action.payload
		},
	},
})

export const { updateCurrentPoint } = PointControllerSlice.actions

export const PointControllerSelector = (state) => state.PointController

export default PointControllerSlice.reducer
