import {
	TELLER_LIST,
	TELLER_LIST_DEFAULT,
	TELLER_LIST_DEFAULT_HOME,
	TELLER_LIST_FAV,
	TELLER_LIST_HOME,
	TELLER_LIST_RECOMMENDED,
} from './TellerListActions'

const tellerListReducer = (state, action) => {
	switch (action.type) {
		case TELLER_LIST_DEFAULT:
			return {
				...state,
				listTeller: { data: [], isLoaded: false, hasMore: false },
			}
		case TELLER_LIST:
			return {
				...state,
				listTeller: {
					data: state.listTeller.data.concat(action.payload),
					hasMore: action.payload.length > 0,
					isLoaded: true,
				},
			}
		case TELLER_LIST_DEFAULT_HOME:
			return {
				...state,
				listTellerHome: { data: [], isLoaded: false, hasMore: false },
			}
		case TELLER_LIST_HOME:
			return {
				...state,
				listTellerHome: {
					data: state.listTellerHome.data?.concat(action.payload),
					hasMore: action.payload.length > 0,
					isLoaded: true,
				},
			}
		case TELLER_LIST_FAV:
			return { ...state, data: action.payload }
		case TELLER_LIST_RECOMMENDED:
			return { ...state, data: action.payload }
		default:
			return state
	}
}

export default tellerListReducer
