import { put, takeLatest } from 'redux-saga/effects'
import {
	showDialogUserIsBlocked,
	hideDialogUserIsBlocked,
	showInviteToPurchase,
	hideInviteToPurchase,
	showInviteToReviewTeller,
	hideInviteToReviewTeller,
} from './GlobalScreenReducer'

// function* showDialogHandler() {
//     yield put({
//         type: showDialogUserIsBlocked().type
//     })
// }

// function* hideDialogHandler() {
//     yield put({
//         type: hideDialogUserIsBlocked().type
//     })
// }

// function* showInviteToPurchaseHandler() {
//     yield put({
//         type: showInviteToPurchase().type
//     })
// }

// function* hideInviteToPurchaseHandler() {
//     yield put({
//         type: hideInviteToPurchase().type
//     })
// }

// function* showInviteToReviewTellerHandler(action) {
//     yield put({
//         type: showInviteToReviewTeller().type,
//         payload: action.payload
//     })
// }

// function* hideInviteToReviewTellerHandler() {
//     yield put({
//         type: hideInviteToReviewTeller().type
//     })
// }

function* GlobalScreenSaga() {}

export default GlobalScreenSaga
