import { GET_TOP_USER } from './RankingActions'

const authReducer = (state, action) => {
	switch (action.type) {
		case GET_TOP_USER:
			return { ...state, dataRanking: action.payload }
		default:
			return state
	}
}

export default authReducer
