import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { APIs } from '../../../api/API'
import {
	doDeleteProfilePartner,
	deleteProfilePartnerSuccess,
	deleteProfilePartnerFail,
	resetStatus,
} from './MyPageReducer'

function* deleteProfilePartner(action) {
	try {
		const resultAPI = yield call(
			APIs.MyPageAPI.deleteProfilePartner,
			action.payload
		)

		if (resultAPI.data.code === 0) {
			yield put({
				type: deleteProfilePartnerSuccess().type,
				payload: resultAPI.data.data,
			})
		} else {
			yield put({
				type: deleteProfilePartnerFail().type,
				payload: resultAPI.data.data,
			})
		}
	} catch (error) {
		console.log('%c  error:', 'color: #0e93e0;background: #aaefe5;', error)
		yield put({
			type: deleteProfilePartnerFail().type,
			payload: error,
		})
	} finally {
		yield put({ type: resetStatus().type, payload: {} })
	}
}

function* MyPageSaga() {
	yield takeEvery(doDeleteProfilePartner().type, deleteProfilePartner)
}

export default MyPageSaga
