export const EVENT_CONNECT = 'connect'
export const EVENT_CONNECTING = 'connecting'
export const AUTHEN = 'authen'
export const TYPING = 'typing'
export const STOP_TYPING = 'stopTyping'
export const RESPONSE = 'response'
export const USER_REQUEST = 'userRequest'
export const POINT = 'point'
export const NEW_MESSAGE = 'newMessage'
export const REQUEST_COUNTER = 'REQUEST_COUNTER'
