export const messageTypes = {
	SENT: 'SENT',
	CALL: 'CALL', //x
	READ: 'READ', //x
	CLOSE_CHAT: 'CLOSE_CHAT', //x
	SEND_STICKER: 'SEND_STICKER',
	CANCEL_REQUEST_CHAT: 'CANCEL_REQUEST_CHAT', //x
	SENT_FAIl: 'SENT_FAIl',
	NOT_ENOUGH_POINT: 'NOT_ENOUGH_POINT',
	POINT: 'POINT',
	AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
	AUTHENTICATE_FAIL: 'AUTHENTICATE_FAIL',
	REQUEST_LIMIT_TIME: 'REQUEST_LIMIT_TIME',
	USER_REQUEST_CALL: 'USER_REQUEST_CALL',
	USER_REQUEST_CHAT: 'USER_REQUEST_CHAT',
	CONVERSATION_READY: 'CONVERSATION_READY',
	RECEIVED_RESPONSE: 'RECEIVED_RESPONSE',
	NO_ACTION_LONG_TIME: 'NO_ACTION_LONG_TIME', //x
	NO_CONNECT_AVAILABLE: 'NO_CONNECT_AVAILABLE',
	EXPECTED_REQUEST_CHAT: 'EXPECTED_REQUEST_CHAT',
	EXPECTED_REQUEST_CALL: 'EXPECTED_REQUEST_CALL',
	CANCEL_EXPECTED_REQUEST_CHAT: 'CANCEL_EXPECTED_REQUEST_CHAT',
	CANCEL_EXPECTED_REQUEST_CALL: 'CANCEL_EXPECTED_REQUEST_CALL',
	CHARGE_TEXT: 'CHARGE_TEXT',
	CHARGE_TEXT_UNOFFICIAL: 'CHARGE_TEXT_UNOFFICIAL', //x
	FREE_TEXT_UNOFFICIAL: 'FREE_TEXT_UNOFFICIAL',
	SEND_FILE_UNOFFICIAL: 'SEND_FILE_UNOFFICIAL', //x
	SEND_FILE_FREE_UNOFFICIAL: 'SEND_FILE_FREE_UNOFFICIAL',
	AUTO_MESSAGE_PICK_BEST_ANSWER: 'AUTO_MESSAGE_PICK_BEST_ANSWER',
	SEND_GIFT: 'SEND_GIFT',
	SENT_FILE: 'SENT_FILE',
	SEND_FILE: 'SEND_FILE',
	SEND_STAMP_UNOFFICIAL: 'SEND_STAMP_UNOFFICIAL',
	SEND_STAMP: 'SEND_STAMP',
}

export const listNewMessage = [
	'FREE_TEXT_UNOFFICIAL',
	'SEND_FILE_UNOFFICIAL',
	'SEND_FILE_FREE_UNOFFICIAL',
	'CHARGE_TEXT_UNOFFICIAL',
	'CHARGE_TEXT',
	'FREE_TEXT',
	'SEND_FILE_FREE',
	'SEND_FILE',
	'SENT_FILE',
	'SENT_TEXT',
	'CANCEL_EXPECTED_REQUEST_CHAT',
	'CANCEL_EXPECTED_REQUEST_CALL',
	'SEND_GIFT',
	'SEND_MSG_ACCEPT_CHAT',
]

export const listUnread = [
	'CHARGE_TEXT_UNOFFICIAL',
	'FREE_TEXT_UNOFFICIAL',
	'SEND_FILE_FREE_UNOFFICIAL',
	'SEND_FILE_UNOFFICIAL',
]

export const listLastChatFee = [
	'CHARGE_TEXT_UNOFFICIAL',
	'SEND_FILE_FREE_UNOFFICIAL',
	'SEND_FILE_UNOFFICIAL',
]

export const listLastChatFree = ['FREE_TEXT_UNOFFICIAL']
