import moment from 'moment'
import * as path from './pathName'
import IconProfileGift from '../assets/ico_profile_gift.png'
import IconProfileIntro from '../assets/ico_profile_intro.png'
import IconProfileSetting2 from '../assets/ico_profile_setting_2.png'
import IconProfileSetting1 from '../assets/ico_profile_setting_1.png'
import IconBlock from '../assets/ico_block.png'
import IconPoint from '../assets/ico_point.png'
import IconProfileHelp from '../assets/ico_profile_help.png'
import IconLogout from '../assets/ico_logout.png'
import ImageGet1PT from '../assets/get_1pt.png'
import ImageGet3PT from '../assets/get_3pt.png'
import ImageGet5PT from '../assets/get_5pt.png'
import ImageGet10PT from '../assets/get_10pt.png'
import ImageGet30PT from '../assets/get_30pt.png'
import ImageGet50PT from '../assets/get_50pt.png'
import IconRankUP from '../assets/rank_arrow_UP.png'
import IconRankKEEP from '../assets/rank_arrow_KEEP.png'
import IconRankNEW from '../assets/rank_arrow_NEW.png'
import IconRankDOWN from '../assets/rank_arrow_DOWN.png'

export const SITES_DO_NOT_ALLOW_EMBED = [
	'google',
	'facebook',
	'twitter',
	'youtube',
	'note.com',
	'apple',
]

export const STELLA_SITES_FOR_USERS = [
	'my.dev.stella-app.com',
	'my.stella-app.com',
]
export const STELLA_WEBVIEW = [
	'webview.stella-app.com',
	'webview.dev.stella-app.com',
]

export const DIMENSION_HEIGHT = {
	HEADER: 61,
	BREADCRUMB: 47,
	FOOTER: 294,
	INPUTBOX: 50,
	BUTTON_REQUEST_CHAT: 69,
	TABBAR_APPOINTMENT: 44,
	TABBAR_STELLA_ANSWER: 55,
	HEADER_CHAT_DETAIL: 52,
}

export const LAST_CHAT = {
	SEND_FILE_FREE_UNOFFICIAL: '写真を送りました',
	SEND_FILE_UNOFFICIAL: '写真を送りました。',
	SEND_STAMP_UNOFFICIAL: 'ステッカー',
	SEND_STAMP: 'ステッカー',
	SENT_FILE: '写真を送りました。',
	SEND_FILE: '写真を送りました。',
	SEND_GIFT: 'ポイントギフトを送信しました',
	SEND_STICKER: 'ステッカー',
	NO_ACTION_LONG_TIME: '【通知】発言が無いため、チャットが終了しました。',
	NO_CONNECT_AVAILABLE:
		'【通知】お客様が一時退出しています。再入室をお待ちください。無料メッセージは送信できます。',
	BUSY_CALL: '応答なし',
	CANCEL_CALL: 'キャンセル',
	LISTENED: '通話時間',
	CHARGE_TEXT_UNOFFICIAL: 'メッセージが届いています。',
	EXPECTED_REQUEST_CHAT: 'チャット相談リクエスト',
	CANCEL_EXPECTED_REQUEST_CHAT:
		'チャット相談リクエストをキャンセルしました。',
	EXPECTED_REQUEST_CALL: '電話相談リクエスト済みです',
	CANCEL_EXPECTED_REQUEST_CALL: '電話相談リクエストをキャンセルしました。',
	CLOSE_CHAT: '【通知】チャットが終了しました。',
	CANCEL_REQUEST_CHAT: 'キャンセル',
	REQUEST_LIMIT_TIME: '応答なし',
}

export const LIST_MSG_TYPE_NOT_UPDATE_UNREAD = [
	'READ',
	'SENT_FILE',
	'SENT_TEXT',
	'SEND_STAMP',
	'SEND_STAMP_UNOFFICIAL',
	'SEND_GIFT',
	'EXPECTED_REQUEST_CHAT',
	'EXPECTED_REQUEST_CALL',
	'CANCEL_EXPECTED_REQUEST_CHAT',
	'CANCEL_EXPECTED_REQUEST_CALL',
]
export const LIST_MSG_TYPE_NOT_UPDATE_LASTCHAT = ['READ']

const content = {
	HOME: 'ホーム',
	NOTIFICATION: 'お知らせ',
	FAVORITE_LIST: 'お気に入り一覧',
	ANSWER: '教えて！先生',
	ANSWER_DETAIL: '質問詳細',
	TIMELINE: 'タイムライン',
	RANKING: 'ランキング',
	BLOG: 'ブログ',
	BLOG_LIST: 'ブログ一覧',
	HOW_TO_USE: '使い方',
	TELLER_LIST: '占い師一覧',
	TELLER_PROFILE: '占い師プロフィール',
	REVIEW: 'レビュー',
	CHAT_HISTORY: '相談履歴',
	MY_PAGE: 'マイページ',
	EDIT_PROFILE: 'マイカルテ編集',
	CAMPAIGN_CODE: 'アカウント・メルマガ設定',
	BLOCK_LIST: 'ブロックリスト',
	ABOUT_PRICE: '料金について',
	DEACTIVE: '退会',
	ACCOUNT_TRANSFER: 'データ引継ぎ',
	FREE_POINT: '無料ポイントGET!!',
	BUY_POINT: 'ポイント購入',
	HELP: 'ヘルプ',
	TERM_OF_SERVICE: '特定商取引法に基づく表示',
	TERM: '利用規約',
	PRIVACY: 'プライバシーポリシー',
	POINT: '決済方法選択',
	INTRODUCE: '紹介でポイントGET',
}

// (timeline, free point, buy point, select payment, introduce friend, account transfer, help, title blog, answer detail, blog, blog list, edit profile) is not done
// free point co the vao tu 2 linh, nen su dung breadcrumb ngan nhat.
export const LIST_BREADCRUMB = {
	NOTIFICATION: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.NOTIFICATION, content: content.NOTIFICATION },
	],
	VERIFY_SMS: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.VERIFY_SMS, content: content.FREE_POINT },
	],
	FAVORITE_LIST: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.FAVORITE_LIST, content: content.FAVORITE_LIST },
	],
	ANSWER: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.ANSWER, content: content.ANSWER },
	],
	FREE_POINT: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.FREE_POINT, content: content.FREE_POINT },
	],
	BUY_POINT: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.BUY_POINT, content: content.BUY_POINT },
	],
	HELP: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.HELP, content: content.HELP },
	],
	ANSWER_DETAIL: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.ANSWER, content: content.ANSWER },
		{ link: path.ANSWER_DETAIL, content: content.ANSWER_DETAIL },
	], // chua lam man hinh nay
	TIMELINE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TIMELINE, content: content.TIMELINE },
	],
	RANKING: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.RANKING, content: content.RANKING },
	],
	BLOG: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.BLOG, content: content.BLOG },
	],
	TERM_OF_USE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TERMS, content: content.TERM },
	],
	PRIVACY: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.PRIVACY, content: content.PRIVACY },
	],
	POINT: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.POINT, content: content.POINT },
	],
	INTRODUCE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.INTRODUCE, content: content.INTRODUCE },
	],
	HOW_TO_USE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.HOW_TO_USE, content: content.HOW_TO_USE },
	],
	TELLER_LIST: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TELLER_LIST, content: content.TELLER_LIST },
	],
	TELLER_PROFILE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TELLER_LIST, content: content.TELLER_LIST },
		{ link: path.TELLER_PROFILE, content: content.TELLER_PROFILE },
	],
	REVIEW: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TELLER_LIST, content: content.TELLER_LIST },
		{ link: path.TELLER_PROFILE, content: content.TELLER_PROFILE },
		{ link: path.REVIEW, content: content.REVIEW },
	],
	TELLER_PROFILE_TIMELINE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TELLER_LIST, content: content.TELLER_LIST },
		{ link: path.TELLER_PROFILE, content: content.TELLER_PROFILE },
		{ link: path.TELLER_PROFILE_TIMELINE, content: content.TIMELINE },
	],
	CHAT_HISTORY: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.CHAT, content: content.CHAT_HISTORY },
	],
	MY_PAGE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
	],
	EDIT_PROFILE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.EDIT_PROFILE, content: content.EDIT_PROFILE },
	],
	CAMPAIGN_CODE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.CAMPAIGN_CODE, content: content.CAMPAIGN_CODE },
	],
	BLOCK_LIST: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.BLOCK_LIST, content: content.BLOCK_LIST },
	],
	ABOUT_PRICE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.ABOUT_PRICE, content: content.ABOUT_PRICE },
	],
	DEACTIVE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.DEACTIVE, content: content.DEACTIVE },
	],
	HOME: [{ link: path.HOME, content: content.HOME }],
	TERM_OF_SERVICE: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.TERM_OF_SERVICE, content: content.TERM_OF_SERVICE },
	],
	ACCOUNT_TRANSFER: [
		{ link: path.HOME, content: content.HOME },
		{ link: path.MY_PAGE, content: content.MY_PAGE },
		{ link: path.ACCOUNT_TRANSFER, content: content.ACCOUNT_TRANSFER },
	],
}

export const CONTENT_TUTORIAL = {
	FIRST_STEP: {
		title: '相談ネームを入力してください',
		content: [
			'「教えて！先生」でのみ使用するハンドルネームです。',
			'相談ネームを入力することで、身バレの心配もありません。',
		],
	},
	SECOND_STEP: {
		title: '「先生にカルテを見せる」に チェックを入れましょう',
		content: [
			'こちらにチェックをいれておくと、',
			'より詳しく回答してもらえます。',
		],
	},
	THIRD_STEP: {
		title: 'カテゴリを選んでください',
		content: [
			'カテゴリを選択する事で、その分野に得意な先生の回答がつきやすくなります！',
		],
	},
}

export const MAXIMUM_CHARACTER_SECOND_TIME_CREATE_QUESTION = 500

export const deviceType = {
	ios: 1,
	android: 2,
}

export const rankingType = {
	day: 'day',
	week: 'week',
	month: 'month',
}

export const TIMELINE_TAB = [
	{ id: 1, title: 'すべて', value: 'all' },
	{ id: 2, title: 'お気に入り', value: 'favorite' },
]

export const MAIN_TAB = [
	{ id: 1, title: 'すべて', value: 'all' },
	{ id: 2, title: 'デビュー', value: 'new' },
	// { id: 3, title: 'チャット', value: 'chat' },
	{ id: 3, title: '相手の気持ち', value: 'genresFeeling' },
	{ id: 4, title: '相性・結婚', value: 'genresMarriage' },
	{ id: 5, title: '復縁・縁結び', value: 'genresMatchMaking' },
	{ id: 6, title: '仕事', value: 'genresJob' },
	{ id: 7, title: '霊感・霊視', value: 'methodFeeling' },
	{ id: 8, title: 'タロット', value: 'methodTarot' },
]

export const SUB_TAB = [
	{ id: 1, title: '前日', value: 'day' },
	{ id: 2, title: '週間', value: 'week' },
	{ id: 3, title: '月間', value: 'month' },
]

export const SORT_TYPE_RANKING = [
	{ id: 1, title: '総合', value: 'position' },
	{ id: 2, title: '鑑定人数', value: 'chatCall' },
]

export const NOTIFICATION_TAB = [
	{ id: 1, title: 'お知らせ', value: 'news' },
	{ id: 2, title: '通知', value: 'all' },
	{ id: 3, title: '先生から', value: 'notificationfromteller' },
]

export const DEFAULT_MAIN_TAB = 'all'
export const DEFAULT_SUB_TAB = 'day'
export const DEFAULT_SORT_TYPE_RANKING = 'position'

export const STATUS_TELLER = {
	WAITING_ALL: 0,
	WAITING_ONLY_CHAT: 1,
	WAITING_ONLY_CALL: 2,
	CHATTING: 3,
	CHATTING_FULL: 4,
	CALLING: 5,
	OFFLINE: 6,
}

export const STATUS_ARROW = {
	KEEP: IconRankKEEP,
	UP: IconRankUP,
	DOWN: IconRankDOWN,
	NEW: IconRankNEW,
}

export const STATUS_ICON = {
	ONLINE: '/img/ico_status_online.png',
	OFFLINE: '/img/ico_status_offline.png',
	BUSY: '/img/ico_status_busy.png',
	CHATTING: '/img/ico_status_chatting.png',
}
export const STATUS_ICON_RANKING = {
	ONLINE: '/img/ico_status_online_ranking.png',
	OFFLINE: '/img/ico_status_offline_ranking.png',
	BUSY: '/img/ico_status_busy_ranking.png',
	CHATTING: '/img/ico_status_chatting_ranking.png',
}

const DATE = new Date()
export const TIMEZONE = Math.abs(DATE.getTimezoneOffset() / 60)

const CATEGORY_ID = {
	// ANYTHING: 1,
	MARRIAGE_DIVORCE: 1,
	MARRIAGE: 2,
	DIVORCE: 3,
	REPUDIATION: 4,
	INFIDELITY: 5,
	LOVE: 6,
	WORK: 7,
	RELATIONS: 8,
	FORTUNE: 9,
	LUCKY_MONEY: 10,
	METAL: 11,
	OTHER: 12,
}

export const CATEGORY_QUESTION = [
	{
		id: 1,
		categoryId: CATEGORY_ID.MARRIAGE_DIVORCE,
		categoryName: '結婚/離婚',
	},
	{ id: 2, categoryId: CATEGORY_ID.MARRIAGE, categoryName: '縁結び' },
	{ id: 3, categoryId: CATEGORY_ID.DIVORCE, categoryName: '縁切り' },
	{ id: 4, categoryId: CATEGORY_ID.REPUDIATION, categoryName: '復縁' },
	{ id: 5, categoryId: CATEGORY_ID.INFIDELITY, categoryName: '不倫' },
	{ id: 6, categoryId: CATEGORY_ID.LOVE, categoryName: '恋愛' },
	{ id: 7, categoryId: CATEGORY_ID.WORK, categoryName: '仕事' },
	{ id: 8, categoryId: CATEGORY_ID.RELATIONS, categoryName: '対人関係' },
	{ id: 9, categoryId: CATEGORY_ID.FORTUNE, categoryName: '運勢' },
	{ id: 10, categoryId: CATEGORY_ID.LUCKY_MONEY, categoryName: '金運' },
	{ id: 11, categoryId: CATEGORY_ID.METAL, categoryName: 'メンタル' },
	{ id: 12, categoryId: CATEGORY_ID.OTHER, categoryName: 'その他' },
]

export const STATUS_QUESTION_ID = {
	WAITING_BEST_ANSWER: 3,
	WAITING_REVIEW: 6,
	ANSWERING: 9,
	SOLVED: 12,
}

export const STATUS_QUESTION = [
	{
		id: 1,
		statusId: STATUS_QUESTION_ID.WAITING_BEST_ANSWER,
		name: 'ベストアンサー待ち',
		showTimeRemaining: false,
		color: '#FF00FF',
	},
	{
		id: 2,
		statusId: STATUS_QUESTION_ID.WAITING_REVIEW,
		name: '事務局審査待ち',
		showTimeRemaining: false,
		color: '#FF3B3B',
	},
	{
		id: 3,
		statusId: STATUS_QUESTION_ID.ANSWERING,
		name: '回答受付中',
		showTimeRemaining: true,
		color: '#a78eda',
	},
	{
		id: 4,
		statusId: STATUS_QUESTION_ID.SOLVED,
		name: '解決済',
		showTimeRemaining: false,
		color: '#A8A8A8',
	},
]

export const CURRENT_YEAR = moment().format('YYYY')
export const CURRENT_MONTH = moment().format('MM')
export const CURRENT_DAY = moment().format('DD')

// export const listWorries = [
//     ['相性', '結婚', '離婚', '夫婦仲'],
//     ['復縁', '不倫', '縁結び', '縁切り'],
//     ['遠距離恋愛', '同性愛', '三角関係',],
//     ['金運', '仕事', '起業', '転職'],
//     ['対人関係', '自分の気持ち', '相手の気持ち'],
//     ['家庭問題', '運勢', '開運方法',],
// ];

// export const consultationStyle = [
//     ['簡潔', '素早い', 'ゆっくり', 'じっくり'],
//     ['丁寧', '優しい', '暖かい', '癒し'],
//     ['ズバッと', '倫理的', 'ユーモア'],
//     ['フレンドリー', 'ポジティブ', 'ポジティブ'],
//     ['聞き上手', '話し上手'],
// ];

// export const divinationMethod = [
//     ['透視', '霊感', '送念', '祈願'],
//     ['祈祷', '波動修正', '遠隔ヒーリング'],
//     ['オーラ', 'ルーン', 'タロット'],
//     ['オラクルカード', 'ルノマンカード'],
//     ['パワーストーン', '数秘術', '東洋占星術'],
//     ['西洋占星術', '夢占い', '血液型'],
//     ['レイキ', 'ダウジング', 'スピリチュアル'],
//     ['チャネリング', 'チャクラ', 'カウンセリング'],
//     ['セラピー', '守護霊対話', '前世観'],
//     ['易', '風水', '人相', '手相'],
//     ['九星気学', '姓名判断', '四柱推命'],
//     ['紫微斗数', '算命学'],
// ];

// export const gender = [
//     ['女', '男', 'その他'],
// ];

export const LIMIT_REQUEST_TIME = 90

export const TELLER_STATUS = {
	WAITING_ALL: 0,
	WAITING_CHAT_ONLY: 1,
	WAITING_CALL_ONLY: 2,
	CHATTING: 3,
	FULL_CHATTING: 4,
	CALLING: 5,
	OFFLINE: 6,
}

export const EXPECTED_REQUEST_TYPE = {
	0: 'HAS_NO_REQUEST',
	1: 'HAS_REQUEST_CHAT',
	2: 'HAS_REQUEST_CALL',
}
export const SUB_HEADER = {
	timeline: 'ホーム > ランキング',
}

export const USER_TYPE_USER = 1
export const USER_TYPE_TELLER = 2
export const listTellerListTab = {
	tabTellerList: 1,
	tabTellerListFav: 2,
	tabTellerListRecommended: 3,
	tabTellerDebut: 4,
}

export const listWorries = [
	[
		{ value: '相性', label: '相性' },
		{ value: '結婚', label: '結婚' },
		{ value: '離婚', label: '離婚' },
		{ value: '夫婦仲', label: '夫婦仲' },
	],
	[
		{ value: '復縁', label: '復縁' },
		{ value: '不倫', label: '不倫' },
		{ value: '縁結び', label: '縁結び' },
		{ value: '縁切り', label: '縁切り' },
	],
	[
		{ value: '遠距離恋愛', label: '遠距離恋愛' },
		{ value: '同性愛', label: '同性愛' },
		{ value: '三角関係', label: '三角関係' },
	],
	[
		{ value: '金運', label: '金運' },
		{ value: '仕事', label: '仕事' },
		{ value: '起業', label: '起業' },
		{ value: '転職', label: '転職' },
	],
	[
		{ value: '対人関係', label: '対人関係' },
		{ value: '自分の気持ち', label: '自分の気持ち' },
		{ value: '相手の気持ち', label: '相手の気持ち' },
	],
	[
		{ value: '家庭問題', label: '家庭問題' },
		{ value: '運勢', label: '運勢' },
		{ value: '開運方法', label: '開運方法' },
	],
]

export const consultationStyle = [
	[
		{ value: '簡潔', label: '簡潔' },
		{ value: '素早い', label: '素早い' },
		{ value: 'ゆっくり', label: 'ゆっくり' },
		{ value: 'じっくり', label: 'じっくり' },
	],
	[
		{ value: '丁寧', label: '丁寧' },
		{ value: '優しい', label: '優しい' },
		{ value: '暖かい', label: '暖かい' },
		{ value: '癒し', label: '癒し' },
	],
	[
		{ value: 'ズバッと', label: 'ズバッと' },
		{ value: '倫理的', label: '倫理的' },
		{ value: 'ユーモア', label: 'ユーモア' },
	],
	[
		{ value: 'フレンドリー', label: 'フレンドリー' },
		{ value: 'ポジティブ', label: 'ポジティブ' },
		{ value: 'ポジティブ', label: 'ポジティブ' },
	],
	[
		{ value: '聞き上手', label: '聞き上手' },
		{ value: '話し上手', label: '話し上手' },
	],
]

export const divinationMethod = [
	[
		{ value: '透視', label: '透視' },
		{ value: '霊感', label: '霊感' },
		{ value: '送念', label: '送念' },
		{ value: '祈願', label: '祈願' },
	],
	[
		{ value: '祈祷', label: '祈祷' },
		{ value: '波動修正', label: '波動修正' },
		{ value: '遠隔ヒーリング', label: '遠隔ヒーリング' },
	],
	[
		{ value: 'オーラ', label: 'オーラ' },
		{ value: 'ルーン', label: 'ルーン' },
		{ value: 'タロット', label: 'タロット' },
	],
	[
		{ value: 'オラクルカード', label: 'オラクルカード' },
		{ value: 'ルノマンカード', label: 'ルノマンカード' },
	],
	[
		{ value: 'パワーストーン', label: 'パワーストーン' },
		{ value: '数秘術', label: '数秘術' },
		{ value: '東洋占星術', label: '東洋占星術' },
	],
	[
		{ value: '西洋占星術', label: '西洋占星術' },
		{ value: '夢占い', label: '夢占い' },
		{ value: '血液型', label: '血液型' },
	],
	[
		{ value: 'レイキ', label: 'レイキ' },
		{ value: 'ダウジング', label: 'ダウジング' },
		{ value: 'スピリチュアル', label: 'スピリチュアル' },
	],
	[
		{ value: 'チャネリング', label: 'チャネリング' },
		{ value: 'チャクラ', label: 'チャクラ' },
		{ value: 'カウンセリング', label: 'カウンセリング' },
	],
	[
		{ value: 'セラピー', label: 'セラピー' },
		{ value: '守護霊対話', label: '守護霊対話' },
		{ value: '前世観', label: '前世観' },
	],
	[
		{ value: '易', label: '易' },
		{ value: '風水', label: '風水' },
		{ value: '人相', label: '人相' },
		{ value: '手相', label: '手相' },
	],
	[
		{ value: '九星気学', label: '九星気学' },
		{ value: '姓名判断', label: '姓名判断' },
		{ value: '四柱推命', label: '四柱推命' },
	],
	[
		{ value: '紫微斗数', label: '紫微斗数' },
		{ value: '算命学', label: '算命学' },
	],
]

export const gender = [
	[
		{ value: 0, label: '女' },
		{ value: 1, label: '男' },
		{ value: 2, label: 'その他' },
	],
]

export const TAB = {
	ALL: {
		value: 2,
		name: 'すべて',
	},
	SOLVED: {
		value: 3,
		name: '解決済',
	},
	MYPOST: {
		value: 'all',
		name: '自分の投稿',
	},
}

export const LIST_CATEGORY_STELLA_ANSWER = [
	{ id: 0, name: 'こだわらない' },
	{ id: 1, name: '結婚/離婚' },
	{ id: 2, name: '縁結び' },
	{ id: 3, name: '縁切り' },
	{ id: 4, name: '復縁' },
	{ id: 5, name: '不倫' },
	{ id: 6, name: '恋愛' },
	{ id: 7, name: '仕事' },
	{ id: 8, name: '対人関係' },
	{ id: 9, name: '運勢' },
	{ id: 10, name: '金運' },
	{ id: 11, name: 'メンタル' },
	{ id: 12, name: 'その他' },
]

export const LIST_VALUE_CATEGORY_STELLA_ANSWER = {
	こだわらない: null,
	'結婚/離婚': 1,
	縁結び: 2,
	縁切り: 3,
	復縁: 4,
	不倫: 5,
	恋愛: 6,
	仕事: 7,
	対人関係: 8,
	運勢: 9,
	金運: 10,
	メンタル: 11,
	その他: 12,
}

export const LIST_TYPE_STELLA_ANSWER = [
	{ id: 0, name: 'こだわらない' },
	{ id: 1, name: '回答受付中' },
	{ id: 2, name: '解決済み' },
]

export const LIST_VALUE_TYPE_STELLA_ANSWER = {
	こだわらない: null,
	回答受付中: 1,
	解決済み: 2,
}

export const LIST_SORTBY_STELLA_ANSWER = [
	{ id: 0, name: '新しい質問順' },
	{ id: 1, name: '古い質問順' },
	{ id: 2, name: '回答が多い順 ' },
	{ id: 3, name: '回答が少ない順' },
	{ id: 4, name: '回答の新しい順' },
]

export const LIST_VALUE_SORTBY_STELLA_ANSWER = {
	新しい質問順: 1,
	古い質問順: 2,
	'回答が多い順 ': 3,
	回答が少ない順: 4,
	回答の新しい順: 5,
}

export const DEFAULT_SIZE_STELLA_ANSWER = 20

export const DEFAULTVALUE_SEARCH = {
	INPUT: '',
	CATEGORY: ['こだわらない'],
	TYPE: ['こだわらない'],
	SORT: '新しい質問順',
}
export const FORMAT_PROFILE_USER = {
	userName: 'userName',
	nickName: 'nickName',
	gender: 'gender',
	birthday: 'birthday',
	placeOfBirth: 'placeOfBirth',
	timeOfBirth: 'timeOfBirth',
	worries: 'worries',
}
export const FORMAT_PROFILE_PARTNER = {
	name: 'name',
	gender: 'gender',
	birthday: 'birthday',
	placeOfBirth: 'placeOfBirth',
	timeOfBirth: 'timeOfBirth',
	relation: 'relation',
}

export const LIST_TITLE_KARTE = {
	name: '名前',
	userName: '名前',
	gender: '性別',
	birthday: '生年月日',
	placeOfBirth: '出生地',
	timeOfBirth: '出生時間',
	worries: '今の悩み',
	relation: '関係',
}

export const GENDER = {
	null: { name: '--', value: '--' },
	1: { name: '男性', value: 'male' },
	2: { name: '女性', value: 'female' },
	3: { name: 'その他', value: 'other' },
}

export const LIST_OPTION_MYPAGE = [
	{
		icon: IconProfileGift,
		name: '[初回限定]無料お試しポイント',
		value: 'free_point',
		link: path.FREE_POINT,
	},
	{
		icon: IconProfileIntro,
		name: '紹介で500pts GET!!',
		value: 'introduction',
		link: path.INTRODUCE,
	},
	{
		icon: IconProfileSetting2,
		name: 'キャンペーンコード 入力',
		value: 'campaign_code',
		link: path.CAMPAIGN_CODE,
	},
	{
		icon: IconProfileSetting1,
		name: 'アカウント・メルマガ設定',
		value: 'account_transfer',
		link: path.ACCOUNT_TRANSFER,
	},
	{
		icon: IconBlock,
		name: 'ブロックリスト',
		value: 'block_list',
		link: path.BLOCK_LIST,
	},
	{
		icon: IconPoint,
		name: '料金について',
		value: 'price',
		link: path.ABOUT_PRICE,
	},
	{ icon: IconProfileHelp, name: 'ヘルプ', value: 'help', link: path.HELP },
	{ icon: IconLogout, name: '退会', value: 'deactive', link: path.DEACTIVE },
]

export const EXCEPT_PLACE_OF_BIRTH = ['東京都', '大阪府', '京都府', '北海道']

export const LIST_PLACE_OF_BIRTH = [
	{ id: 1, name: '北海道' },
	{ id: 2, name: '青森' },
	{ id: 3, name: '岩手' },
	{ id: 4, name: '宮城' },
	{ id: 5, name: '秋田' },
	{ id: 6, name: '山形' },
	{ id: 7, name: '福島' },
	{ id: 8, name: '茨城' },
	{ id: 9, name: '栃木' },
	{ id: 10, name: '群馬' },
	{ id: 11, name: '埼玉' },
	{ id: 12, name: '千葉' },
	{ id: 13, name: '東京都' },
	{ id: 14, name: '神奈川' },
	{ id: 15, name: '新潟' },
	{ id: 16, name: '富山' },
	{ id: 17, name: '石川' },
	{ id: 18, name: '福井' },
	{ id: 19, name: '山梨' },
	{ id: 20, name: '長野' },
	{ id: 21, name: '岐阜' },
	{ id: 22, name: '静岡' },
	{ id: 23, name: '愛知' },
	{ id: 24, name: '三重' },
	{ id: 25, name: '滋賀' },
	{ id: 26, name: '京都府' },
	{ id: 27, name: '大阪府' },
	{ id: 28, name: '兵庫' },
	{ id: 29, name: '奈良' },
	{ id: 30, name: '和歌山' },
	{ id: 31, name: '鳥取' },
	{ id: 32, name: '島根' },
	{ id: 33, name: '岡山' },
	{ id: 34, name: '広島' },
	{ id: 35, name: '山口' },
	{ id: 36, name: '徳島' },
	{ id: 37, name: '香川' },
	{ id: 38, name: '愛媛' },
	{ id: 39, name: '高知' },
	{ id: 40, name: '福岡' },
	{ id: 41, name: '佐賀' },
	{ id: 42, name: '長崎' },
	{ id: 43, name: '熊本' },
	{ id: 44, name: '大分' },
	{ id: 45, name: '宮崎' },
	{ id: 46, name: '鹿児島' },
	{ id: 47, name: '沖縄' },
]

export const LIST_WORRY = [
	{ id: 1, name: '相性' },
	{ id: 2, name: '結婚' },
	{ id: 3, name: '離婚' },
	{ id: 4, name: '夫婦仲' },
	{ id: 5, name: '復縁' },
	{ id: 6, name: '不倫' },
	{ id: 7, name: '縁結び' },
	{ id: 8, name: '縁切り' },
	{ id: 9, name: '遠距離恋愛' },
	{ id: 10, name: '同性愛' },
	{ id: 11, name: '三角関係' },
	{ id: 12, name: '金運' },
	{ id: 13, name: '仕事' },
	{ id: 14, name: '起業' },
	{ id: 15, name: '転職' },
	{ id: 16, name: '対人関係' },
	{ id: 17, name: '自分の気持ち' },
	{ id: 18, name: '相手の気持ち' },
	{ id: 19, name: '家庭問題' },
	{ id: 20, name: '運勢' },
	{ id: 21, name: '開運方法' },
]

export const USER = 'user'

export const TYPE = {
	0: 'user',
	1: 'partner1',
	2: 'partner2',
}

const MUST_RATE_STAR = '☆を最低一つは選択してください。'
const MUST_ENTER_LONG_REVIEW =
	'占い師にしっかりと接客の内容を伝えるために30文字以上のコメントをお願いします。'

export const MESSAGE = {
	USER_REVIEW: {
		MUST_RATE_STAR: MUST_RATE_STAR,
		MUST_ENTER_LONG_REVIEW: MUST_ENTER_LONG_REVIEW,
	},
}

export const CONSTELLATION_TYPE = {
	1: { point: ImageGet1PT, star: 3 },
	2: { point: ImageGet3PT, star: 3 },
	3: { point: ImageGet5PT, star: 3 },
	4: { point: ImageGet10PT, star: 4 },
	5: { point: ImageGet30PT, star: 4 },
	6: { point: ImageGet50PT, star: 5 },
}

export const VERIFY_SMS_ERROR_TYPE = {
	PHONE_NUMBER: 'phoneNumber',
	VERIFY_WRONG_CODE: 'verifyWrongCode',
	VERIFY_EXPIRED_CODE: 'verifyExpiredCode',
}

export const CHAT_TELLER = {
	MAX_POPUP_SPAM: 3,
	MAX_POPUP_SPAM_TWO: 5,
	MAX_POPUP_REQUEST_CHAT: 6,
	RESET_COUNTER: 0,
}
