export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY'
export const ADD_NEW_CHAT_HISTORY = 'ADD_NEW_CHAT_HISTORY'
export const GET_MESSAGE_LIST = 'GET_MESSAGE_LIST'
export const GET_FAVORITE_LIST = 'GET_FAVORITE_LIST'
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE'
export const REQUEST_CHAT = 'REQUEST_CHAT'
export const REAL_TIME_MESSAGE = 'REAL_TIME_MESSAGE'
export const SET_APPOINTMENT = 'SET_APPOINTMENT'
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT'
export const CHECK_INCONVERSATION = 'CHECK_INCONVERSATION'
export const CLOSE_CHAT = 'CLOSE_CHAT'
export const GET_RESPONSE_REQUEST_SOCKET = 'GET_RESPONSE_REQUEST_SOCKET'
export const CLEAR_DATA_CHAT = 'CLEAR_DATA_CHAT'
export const DELETE_MSG_ERROR = 'DELETE_MSG_ERROR'
export const RECONNECT_CHAT = 'RECONNECT_CHAT'
export const BENCH_TIME = 'BENCH_TIME'
export const TELLER_READY_CHAT = 'TELLER_READY_CHAT'
export const READ_MESSAGE = 'READ_MESSAGE'
export const REMOVE_UNFAVORITE_TELLER = 'REMOVE_UNFAVORITE_TELLER'
export const FAVORITE_TELLER_SUCCESS = 'FAVORITE_TELLER_SUCCESS'
export const FAVORITE_TELLER_FAIL = 'FAVORITE_TELLER_FAIL'
export const FAVORITE_TELLER_CLEAR = 'FAVORITE_TELLER_CLEAR'
export const DATA_LIST_CHAT = 'DATA_LIST_CHAT'
export const CHECK_USER_FIRST_CHAT_ONLINE = 'CHECK_USER_FIRST_CHAT_ONLINE'
