import React from 'react'
import Popup from 'react-popup/dist'
import './index.scss'

function PopupDefaut() {
	return (
		<Popup
			className="mm-popup"
			defaultOk="はい"
			defaultCancel="キャンセル"
			closeBtn={false}
		/>
	)
}

export default PopupDefaut
