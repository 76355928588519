import { GET_LIST_GIFT } from './GiftActions'

const GiftReducer = (state, action) => {
	switch (action.type) {
		case GET_LIST_GIFT:
			return { ...state, listGift: action.payload }
		default:
			return state
	}
}

export default GiftReducer
