import {
	UNBLOCK,
	BLOCK,
	GET_USERINFO,
	GET_BANNERS,
	GET_REVIEWMODE,
	UPDATE_USER_PROFILE,
	GET_BLOCK_LIST,
	GET_POINT_CAMPAIGN_CODE,
	GET_BANNERS_MYPAGE,
	CLEAR_DATA_BLOCK,
} from './MyPageAction'

const myPageReducer = (state, action) => {
	switch (action.type) {
		case GET_USERINFO:
			return {
				...state,
				userInfo: { data: action.payload, isDone: true },
			}
		case GET_BANNERS:
			return { ...state, bannerList: action.payload }
		case GET_BANNERS_MYPAGE:
			return { ...state, bannerMyPageList: action.payload }
		case GET_REVIEWMODE:
			return { ...state, reviewMode: action.payload }
		case UPDATE_USER_PROFILE:
			return { ...state, data: action.payload }
		case GET_BLOCK_LIST:
			return {
				...state,
				blockList: {
					data: state.blockList.data.concat(action.payload),
					hasMore: action.payload.length > 0,
				},
			}
		case UNBLOCK:
			return { ...state }
		case BLOCK:
			return { ...state }
		case GET_POINT_CAMPAIGN_CODE:
			return {
				...state,
				dataCampaignCode: { data: action.payload, error: action.error },
			}
		case CLEAR_DATA_BLOCK:
			return { ...state, blockList: { data: [], hasMore: false } }
		default:
			return state
	}
}

export default myPageReducer
