import { SEND_REVIEW } from './UserReviewActions'

const userReviewReducer = (state, action) => {
	switch (action.type) {
		case SEND_REVIEW:
			return { ...state, data: action.payload }
		default:
			return state
	}
}

export default userReviewReducer
