import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
import MyPageReducer from '../screens/MyPage/MyPagaSagas/MyPageReducer'
import ChatReducer from '../screens/Chat/ChatSagas/ChatReducer'
import GlobalScreenReducer from '../screens/GlobalScreen/GlobalScreenSagas/GlobalScreenReducer'
import VerifySMSReducer from '../screens/VerifySMS/VerifySMSSagas/VerifySMSReducer'
import PointControllerReducer from '../screens/PointController/PointControllerSagas/PointControllerReducer'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
	reducer: {
		GlobalScreen: GlobalScreenReducer,
		MyPage: MyPageReducer,
		Chat: ChatReducer,
		VerifySMS: VerifySMSReducer,
		PointController: PointControllerReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(sagaMiddleware), // MOUNT SAGA MIDDLE WARE
})

// RUN SAGA MIDDLEWARE
sagaMiddleware.run(rootSaga)
