import md5 from 'md5'
import * as Fetch from '../../util/Fetch'
import Popup from 'react-popup'
import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import {
	appConfig,
	dynamicLinkUrl,
	domainUriPrefix,
	androidPackageName,
	iosBundleId,
} from '../../constants/generalConfig'
import { ADD_ERROR, LOGIN, CLEAR_ERROR, LOGOUT } from './AuthActions'
import { RESPONSE_CODE } from '../../constants/responseCode'
import {
	MESSAGE_OLD_EMAIL_NOT_EXIST,
	MESSAGE_INCORRECT_PASSWORD,
} from '../../constants/messages'
import AuthReducer from './AuthReducer'

const clearErrorMessage = (dispatch) => () => {
	dispatch({ type: CLEAR_ERROR })
}

const setStorageLogin = (response) => {
	const token = response.data.data.token
	const userId = response.data.data.userId
	const isFinishTutorial = response.data.data.isReadStellaAnswerTopTutorial

	localStorage.setItem('token', token)
	localStorage.setItem('isFinishTutorial', isFinishTutorial)
	localStorage.setItem('token', token)
	localStorage.setItem('userId', userId)
	localStorage.setItem('user', JSON.stringify(response.data.data))
	Fetch.setExpireToken(token)
	localStorage.setItem('userId', response.data.data.userId)
	// localStorage.setItem('currentPoint', response.data.data?.point );
	localStorage.setItem('loadFavoriteList', false)
	localStorage.setItem(
		'constellationType',
		response.data.data.constellationType
	)
	localStorage.setItem(
		'constellationSign',
		response.data.data.constellationSign
	)
	localStorage.setItem(
		'numberUnreadNotification',
		response.data.data.numberUnreadNotification
	)
}

const login =
	(dispatch) =>
		async ({ email, password }, isPwdMd5 = false) => {
			const pwd = isPwdMd5 ? password : md5(password)
			const params = {
				email,
				password: pwd,
				applicationName: 'stella-web',
				application: 'stella',
				deviceType: 7,
				deviceId: '630c725a16a0d96a',
				pushNotificationToken: 'abcv',
				applicationVersion: '4.2',
				deviceName: 'Iphone',
			}

			return serverApi
				.post(appConfig.api_base_url + appConfig.path_login, params)
				.then((response) => {
					if (response.data.code === RESPONSE_CODE.SUCCESS) {
						setStorageLogin(response)
						Fetch.rememberEmailPassword(pwd, email)
						dispatch({ type: LOGIN, payload: response.data.data.token })
					}
					if (
						response.data.code === RESPONSE_CODE.OLDEMAIL_NOT_EXIST ||
						response.data.code === RESPONSE_CODE.EMAIL_CHANGED
					) {
						Popup.alert(MESSAGE_OLD_EMAIL_NOT_EXIST)
					}
					if (response.data.code === RESPONSE_CODE.INCORRECT_PASSWORD) {
						Popup.alert(MESSAGE_INCORRECT_PASSWORD)
					}
					return response
				})
				.catch((err) => {
					dispatch({
						type: ADD_ERROR,
						payload: err.response,
					})
				})
		}

const loginFB = (dispatch) => async (data) => {
	const params = {
		facebookToken: data.token,
		deviceType: 7,
		deviceName: '1',
		applicationVersion: '1',
		pushNotificationToken: '1',
		applicationName: 'stella-web',
		application: 'stella-web',
		autoLogin: false,
		deviceId: '1',
		advertainmentId: '1',
		browserId: data.browserId

	}
	return serverApi
		.post(appConfig.api_base_url + appConfig.path_login, params)
		.then((response) => {
			if (response.data.code === RESPONSE_CODE.SUCCESS) {
				setStorageLogin(response)
			}

			return response
		})
}

const loginGoogle = (dispatch) => (googleToken, googleId, browserId) => {
	const params = {
		googleToken,
		googleId,
		deviceType: 7,
		deviceName: '1',
		applicationVersion: '1',
		pushNotificationToken: '1',
		applicationName: 'stella-web',
		application: 'stella-web',
		autoLogin: false,
		deviceId: '1',
		advertainmentId: '1',
		browserId
	}
	return serverApi
		.post(appConfig.api_base_url + appConfig.path_login, params)
		.then((response) => {
			if (response.data.code === RESPONSE_CODE.SUCCESS) {
				setStorageLogin(response)
			}

			return response
		})
}

const logout = (dispatch) => async (comment) => {
	const userId = await localStorage.getItem('userId')
	const params = {
		comment,
		pushNotificationToken: 'nnn',
	}
	await serverApi.put(appConfig.path_logout + `/${userId}/deactivate`, params)
	Fetch.clearStorage()
	dispatch({ type: LOGOUT })
}

const verifyForgotPass = (dispatch) => async (email) => {
	const params = {
		email,
		applicationName: 'stella',
	}

	return serverApi
		.post(appConfig.path_verify_code, params)
		.then((response) => {
			return response
		})
}

const ForgotPassword = (dispatch) => async (email, newPassword, authenCode) => {
	const params = {
		email,
		newPassword: newPassword,
		deviceType: 7,
		deviceName: '1',
		applicationVersion: '1',
		pushNotificationToken: '1',
		applicationName: 'stella',
		deviceId: '1',
	}
	return serverApi.put(appConfig.path_verify_code + `/${authenCode}`, params)
}

const register =
	(dispatch) =>
		async (
			sex,
			birthday,
			dataWorries,
			subName,
			name,
			email,
			token,
			googleId,
			browserID
		) => {
			const params = {
				userName: subName + name,
				gender: sex,
				birthday,
				deviceType: 7,
				deviceId: 'abc123',
				placeOfBirth: '',
				deviceName: 'pc',
				applicationVersion: '4.2',
				pushNotificationToken: '1',
				applicationName: 'stella-web',
				application: 'stella-web',
				worries: dataWorries,
				advertainmentId: '11',
				browserId: browserID
			}

			if (token) {
				params.facebookToken = token
			}

			if (googleId) {
				params.googleToken = token
				params.googleId = googleId
				delete params.facebookToken
			}

			if (!token && !googleId) {
				params.email = email
			}

			return serverApi.post(
				appConfig.api_base_url + appConfig.path_update_user_info,
				params
			)
		}

const UpdateAccUser =
	(dispatch) => (userId, newEmail, currentPwd, newPassword) => {
		const params = {
			newEmail,
			oldPassword: currentPwd ? md5(currentPwd) : null,
			newPassword,
		}

		return serverApi
			.put(appConfig.path_update_user_info + `${userId}/accounts`, params)
			.then((response) => response)
	}

const SettingEmailMagazine = (dispatch) => (isHasAdsEmail) => {
	return serverApi
		.put(
			appConfig.path_update_user_info +
			`settingemailmagazine?isSettingEmailMagazine=${isHasAdsEmail}`
		)
		.then((response) => response)
}

const reportUser = (dispatch) => (friendId, reportReason) => {
	const params = {
		friendId,
		reportReason,
	}
	return serverApi
		.post(appConfig.path_report_user, params)
		.then((response) => response)
}

const getTellerRegister = (dispatch) => () => {
	return serverApi
		.get(appConfig.api_base_url + appConfig.path_get_teller_register)
		.then((response) => response)
}
const getTellerIntroduction = (dispatch) => () => {
	return serverApi.get(appConfig.api_base_url + appConfig.path_get_teller_introduction)
		.then(response => response)
}
const getTellerReview = (dispatch) => () => {
	return serverApi.get(appConfig.api_base_url + appConfig.path_get_teller_review)
		.then(response => response)
}
const getTellerInfoRegister = (dispatch) => (tellerId) => {
	return serverApi
		.get(
			appConfig.api_base_url +
			appConfig.path_get_teller_register +
			`/${tellerId}`
		)
		.then((response) => response)
}

const refreshToken = (dispatch) => () => {
	return serverApi.get(appConfig.path_refresh_token).then((res) => {
		if (res.data.code === 0) {
			Fetch.setExpireToken(res.data.data.token)
			Fetch.setToken(res.data.data.token)
		}
	})
}

const dynamicLinks = (dispatch) => (tellerID) => {
	const hostName = window.location.origin
	const userID = localStorage.getItem('userId')
	let link = `${hostName}/introduce/person?userID=${userID}`
	if (tellerID) {
		link += `&tellerID=${tellerID}`
	}
	const params = {
		dynamicLinkInfo: {
			domainUriPrefix,
			link,
			androidInfo: {
				androidPackageName,
			},
			iosInfo: {
				iosBundleId,
			},
		},
	}
	return serverApi.post(dynamicLinkUrl, params).then((res) => res)
}

export const { Provider, Context, actions } = createDataContext(
	AuthReducer,
	{
		login,
		logout,
		clearErrorMessage,
		verifyForgotPass,
		ForgotPassword,
		register,
		loginFB,
		UpdateAccUser,
		SettingEmailMagazine,
		reportUser,
		loginGoogle,
		getTellerRegister,
		getTellerIntroduction,
		getTellerReview,
		getTellerInfoRegister,
		refreshToken,
		dynamicLinks,
	},
	{ token: null, errorMessage: '' }
)
