import { appConfig } from '../../constants/generalConfig'
import serverApi from '../serverApi'

export const ChatAPI = (...params) => {
	const fetchTellerProfile = (params = {}) => {
		return serverApi.get(
			appConfig.fortuner_teller + '/' + params.fortuneTellerId,
			{}
		)
	}

	return {
		fetchTellerProfile,
	}
}
