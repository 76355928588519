import createDataContext from '../createDataContext'
import FavoriteMessageReducer from './FavoriteMessageReducer'
import { ADD_ITEM, REMOVE_ALL, REMOVE_ITEM } from './FavoriteMessageActions'

const addItem =
	(dispatch) =>
	({ tellerId, msgId }, type) => {
		if (type === 'loadFavoriteList') {
			dispatch({ type: ADD_ITEM, payload: msgId })
		} else {
			dispatch({ type: ADD_ITEM, payload: { tellerId, msgId } })
		}
	}

const removeItem = (dispatch) => (msgId, type) => {
	dispatch({ type: REMOVE_ITEM, payload: { msgId, type } })
}

const removeAll = (dispatch) => () => {
	dispatch({ type: REMOVE_ALL })
}

export const { Provider, Context, actions } = createDataContext(
	FavoriteMessageReducer,
	{ addItem, removeItem, removeAll },
	{ listFavoriteMessage: [] }
)
