import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
// import { navigateTo } from '../../util/Navigation';
import {
	GET_TOP_RANK_USER,
	GET_TELLER_LIST,
	GET_ANSWER_LIST,
	GET_TIMELINE_LIST,
	LIKE_POST,
	UNLIKE_POST,
	GET_NEWS,
	GET_USER,
} from './HomeActions'
import HomeReducer from './HomeReducer'

const getUser = (dispatch) => (userId) => {
	return serverApi
		.get(appConfig.path_get_user + `/${userId}`)
		.then((response) => {
			if (response.data.code === 0) {
				// localStorage.setItem('currentPoint', response.data.data?.point );
				dispatch({ type: GET_USER, payload: response.data.data })
				return { isLoaded: true, data: response.data.data }
			} else {
				return false
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getTopRankTeller = (dispatch) => () => {
	serverApi
		.get(appConfig.path_ranking_top)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_TOP_RANK_USER,
					payload: response.data.data,
				})
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getTellerList = (dispatch) => () => {
	serverApi
		.get(appConfig.path_teller_list + '?page=0&size=120&tab=all')
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_TELLER_LIST, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getNews = (dispatch) => () => {
	serverApi
		.get(appConfig.path_news)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_NEWS, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getAnswerList = (dispatch) => () => {
	serverApi
		.get(appConfig.path_answer_list + '?tab=2&page=0&size=8')
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_ANSWER_LIST,
					payload: response.data.data.list,
				})
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getTimelineList =
	(dispatch) =>
	(tab = 'all', skip = 0, take = 30) => {
		serverApi
			.get(
				appConfig.path_timeline_list +
					`?tab=${tab}&page=${skip}&size=${take}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_TIMELINE_LIST,
						payload: response.data.data,
					})
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

const likePost = (dispatch) => (postId) => {
	serverApi
		.post(appConfig.path_like_post, { postId })
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: LIKE_POST, payload: true })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const disLikePost = (dispatch) => (postId) => {
	serverApi
		.delete(appConfig.path_like_post + `?postId=${postId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: UNLIKE_POST, payload: false })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

export const { Provider, Context, actions } = createDataContext(
	HomeReducer,
	{
		getUser,
		getTopRankTeller,
		getTellerList,
		getAnswerList,
		getTimelineList,
		likePost,
		disLikePost,
		getNews,
	},
	{
		userProfile: {},
		topRankingList: {},
		tellerList: [],
		answerList: [],
		timelineList: [],
		liked: false,
		newsList: [],
	}
)
