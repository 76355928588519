import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import {
	GET_USERINFO,
	GET_BANNERS,
	GET_REVIEWMODE,
	UPDATE_USER_PROFILE,
	GET_BLOCK_LIST,
	UNBLOCK,
	GET_POINT_CAMPAIGN_CODE,
	GET_BANNERS_MYPAGE,
	CLEAR_DATA_BLOCK,
} from './MyPageAction'
import MyPageReducer from './MyPageReducer'
import { USER } from '../../constants/constant'

const getUserInfo = (dispatch) => (userID) => {
	return serverApi
		.get(`${appConfig.path_partners}${userID}`)
		.then((response) => {
			if (response.data.code === 0) {
				const userInfo = JSON.parse(localStorage.getItem('user'))
				userInfo.isEnoughInfo = response.data.data.isEnoughInfo
				localStorage.setItem('user', JSON.stringify(userInfo))
				dispatch({ type: GET_USERINFO, payload: response.data.data })
			}
			return response
		})
		.catch((err) => {
			console.log(err)
		})
}

const getBanners = (dispatch) => () => {
	serverApi
		.get(appConfig.path_banners)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_BANNERS, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getBannersMyPage = (dispatch) => () => {
	serverApi
		.get(appConfig.path_banners_mypage)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({
					type: GET_BANNERS_MYPAGE,
					payload: response.data.data,
				})
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const getReviewModeSetting = (dispatch) => () => {
	serverApi
		.get(appConfig.path_reviewmode_setting)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_REVIEWMODE, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const updateUserProfile = (dispatch) => async (data, type) => {
	const optionalType = type === USER ? '' : type

	const paramsUser = {
		userName: data.userName,
		nickName: data.nickName,
		birthday: data.birthday,
		timeOfBirth: data.timeOfBirth,
		gender: parseInt(data.gender),
		placeOfBirth: data.placeOfBirth,
		worries: data.worries,
		about: '',
	}
	const paramsPartner = {
		name: data.userName,
		nickName: data.nickName,
		birthday: data.birthday,
		timeOfBirth: data.timeOfBirth,
		gender: parseInt(data.gender),
		placeOfBirth: data.placeOfBirth,
		worries: [],
		relation: data.relation,
	}
	const params = type === USER ? paramsUser : paramsPartner
	const userId = await localStorage.getItem('userId')
	return serverApi
		.put(
			`${appConfig.path_update_user_info}${userId}/${optionalType}`,
			params
		)
		.then((response) => response)
		.catch((err) => {
			console.log(err)
		})
}

const getBlockList =
	(dispatch) =>
	async ({ page, size }) => {
		serverApi
			.get(`${appConfig.path_block}?page=${page}&size=${size}`)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_BLOCK_LIST,
						payload: response.data.data,
					})
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

const unBlock = (dispatch) => async (id) => {
	serverApi
		.delete(`${appConfig.path_block}?friendId=${id}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: UNBLOCK, payload: response.data.data })
			}
		})
		.catch((err) => {
			console.log(err)
		})
}

const block = (dispatch) => async (id) => {
	const params = {
		friendId: id,
	}
	return serverApi
		.post(`${appConfig.path_block}`, params)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: UNBLOCK, payload: response.data.data })
			}
			return response
		})
		.catch((err) => {
			console.log(err)
		})
}

const getPointCampaignCode =
	(dispatch) =>
	async ({ campaignCode }) => {
		serverApi
			.post(`${appConfig.path_campaigncode}?codeName=${campaignCode}`)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_POINT_CAMPAIGN_CODE,
						payload: response.data.data,
						error: false,
					})
				} else {
					dispatch({
						type: GET_POINT_CAMPAIGN_CODE,
						payload: response.data.data,
						error: true,
					})
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

const clearDataBlock = (dispatch) => () => {
	dispatch({ type: CLEAR_DATA_BLOCK })
}

export const { Provider, Context, actions } = createDataContext(
	MyPageReducer,
	{
		getUserInfo,
		getBanners,
		getReviewModeSetting,
		updateUserProfile,
		getBlockList,
		unBlock,
		block,
		getPointCampaignCode,
		getBannersMyPage,
		clearDataBlock,
	},
	{
		userInfo: { data: [], isDone: false },
		bannerList: {},
		bannerMyPageList: {},
		reviewMode: {},
		data: {},
		blockList: { data: [], hasMore: false },
		dataCampaignCode: { data: {}, error: null },
	}
)
