import { put, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { APIs } from '../../../api/API'
import {
	doVerifyPhoneNumber,
	doVerifyPhoneNumberFail,
	doVerifyPhoneNumberSuccess,
	doVerifyCodeSMS,
	doVerifyCodeSMSFail,
	doVerifyCodeSMSSuccess,
	resetStatus,
} from './VerifySMSReducer'

function* verifyPhoneNumber(action) {
	try {
		const resultAPI = yield call(
			APIs.VerifySMSAPI.verifyPhoneNumber,
			action.payload
		)
		console.log(
			'%c  resultAPI:',
			'color: #0e93e0;background: #aaefe5;',
			resultAPI
		)

		if (resultAPI.data.code === 0) {
			yield put({
				type: doVerifyPhoneNumberSuccess().type,
				payload: resultAPI.data,
			})
		} else {
			yield put({
				type: doVerifyPhoneNumberFail().type,
				payload: resultAPI.data,
			})
		}
	} catch (error) {
		console.log('%c  error:', 'color: #0e93e0;background: #aaefe5;', error)
		yield put({
			type: doVerifyPhoneNumberFail().type,
			payload: error,
		})
	} finally {
		yield put({ type: resetStatus().type, payload: {} })
	}
}

function* verifyCodeSMS(action) {
	try {
		const resultAPI = yield call(
			APIs.VerifySMSAPI.verifyCodeSMS,
			action.payload
		)
		console.log(
			'%c  resultAPI:',
			'color: #0e93e0;background: #aaefe5;',
			resultAPI
		)

		if (resultAPI.data.code === 0) {
			yield put({
				type: doVerifyCodeSMSSuccess().type,
				payload: resultAPI.data,
			})
		} else {
			yield put({
				type: doVerifyCodeSMSFail().type,
				payload: resultAPI.data,
			})
		}
	} catch (error) {
		console.log('%c  error:', 'color: #0e93e0;background: #aaefe5;', error)
		yield put({
			type: doVerifyCodeSMSFail().type,
			payload: error,
		})
	} finally {
		yield put({ type: resetStatus().type, payload: {} })
	}
}

function* VerifySMSSaga() {
	yield takeLatest(doVerifyPhoneNumber().type, verifyPhoneNumber)
	yield takeLatest(doVerifyCodeSMS().type, verifyCodeSMS)
}

export default VerifySMSSaga
