import React from 'react'
import { Provider as AuthProvider } from './contexts/Auth/AuthContext'
import { Provider as GiftProvider } from './contexts/Gift/GiftContext'
import { Provider as HomeProvider } from './contexts/Home/HomeContext'
import { Provider as ChatProvider } from './contexts/Chat/ChatContext'
import { Provider as RankingProvider } from './contexts/Ranking/RankingContext'
import { Provider as WallPaperProvider } from './contexts/WallPaper/WallPaperContext'
import { Provider as UserReviewProvider } from './contexts/UserReview/UserReviewContext'
import { Provider as TellerListContext } from './contexts/TellerList/TellerListContext'
import { Provider as StellaAnswerContext } from './contexts/StellaAnswer/StellaAnswerContext'
import { Provider as TellerProfileContext } from './contexts/TellerProfile/TellerProfileContext'
import { Provider as TellerInfoProvider } from './contexts/TellerInfo/TellerInfoContext'
import { Provider as MyPageProvider } from './contexts/MyPage/MyPageContext'
import { Provider as NotificationProvider } from './contexts/Notification/NotificationContext'
import { Provider as TimelineProvider } from './contexts/Timeline/TimelineContext'
import { Provider as FavoriteList } from './contexts/FavoriteList/FavoriteListContext'
import { Provider as FavoriteMessageProvider } from './contexts/FavoriteMessage/FavoriteMessageContext'
import { Provider as ChangeStateChatDetailProvider } from './contexts/ChangeStateChatDetail/ChangeStateChatDetailContext'
import { Provider as BuyPointProvider } from './contexts/BuyPoint/BuyPointContext'

export const MainProvider = ({ children }) => {
	const ListProvider = [
		AuthProvider,
		TellerInfoProvider,
		HomeProvider,
		HomeProvider,
		RankingProvider,
		TellerListContext,
		WallPaperProvider,
		StellaAnswerContext,
		TellerProfileContext,
		ChatProvider,
		GiftProvider,
		MyPageProvider,
		WallPaperProvider,
		UserReviewProvider,
		NotificationProvider,
		TimelineProvider,
		FavoriteList,
		FavoriteMessageProvider,
		ChangeStateChatDetailProvider,
		BuyPointProvider,
	].reduce(
		(PreProvider, CurrentProvider) =>
			({ children }) =>
				(
					<PreProvider>
						<CurrentProvider>{children}</CurrentProvider>
					</PreProvider>
				),
		({ children }) => <>{children}</>
	)

	return <ListProvider>{children}</ListProvider>
}
