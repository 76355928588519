import {
	TELLER_PROFILE_DETAIL,
	TELLER_PROFILE_DEFAULT,
	ADD_FAVORITE,
	REMOVE_FAVORITE,
	GET_REVIEWS_TELLER,
	TELLER_PROFILE_POSTS,
	TELLER_POSTS_DEFAULT,
} from './TellerProfileActions'

const tellerProfileReducer = (state, action) => {
	switch (action.type) {
		case TELLER_PROFILE_DEFAULT:
			return { ...state, data: null }
		case TELLER_PROFILE_DETAIL:
			return { ...state, data: action.payload }
		case ADD_FAVORITE:
			const newDataAdd = { ...state.data }
			newDataAdd.favorited = true
			return { ...state, data: newDataAdd }
		case REMOVE_FAVORITE:
			const newDataRemove = { ...state.data }
			newDataRemove.favorited = false
			return { ...state, data: newDataRemove }
		case GET_REVIEWS_TELLER:
			return { ...state, dataReviewTeller: action.payload }
		case TELLER_POSTS_DEFAULT:
			return { ...state, dataPostTeller: null }
		case TELLER_PROFILE_POSTS:
			return { ...state, dataPostTeller: action.payload }
		default:
			return state
	}
}

export default tellerProfileReducer
