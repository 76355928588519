import { GET_WALLPAPERS } from './WallPaperAction'

const homeReducer = (state, action) => {
	switch (action.type) {
		case GET_WALLPAPERS:
			return { ...state, wallpaperList: action.payload }
		default:
			return state
	}
}

export default homeReducer
