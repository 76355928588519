export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS'
export const GET_ALL_QUESTIONS_ONCLICK = 'GET_ALL_QUESTIONS_ONCLICK'
export const GET_MYPOST = 'GET_MYPOST'
export const GET_MYPOST_ONCLICK = 'GET_MYPOST_ONCLICK'
export const SEARCH_QUESTIONS = 'SEARCH_QUESTIONS'
export const LOADMORE_SEARCH_MYPOST = 'LOADMORE_SEARCH_MYPOST'
export const LOADMORE_SEARCH_QUESTIONS = 'LOADMORE_SEARCH_QUESTIONS'
export const SEARCH_MYPOST = 'SEARCH_MYPOST'
export const CHECK_FINISH_TUTORIAL = 'CHECK_FINISH_TUTORIAL'
export const GET_SETTING_POINT = 'GET_SETTING_POINT'
export const CHECK_PURCHASED = 'CHECK_PURCHASED'
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const RESET_LIST_QUESTION = 'RESET_LIST_QUESTION'
export const RESET_LIST_MY_POST = 'RESET_LIST_MY_POST'
export const GET_ANSWERS = 'GET_ANSWERS'
export const LIKE = 'LIKE'
export const UNLIKE = 'UNLIKE'
export const RESET_LIST_ANSWER = 'RESET_LIST_ANSWER'
export const RESET_PAGE_LIST_ANSWER = 'RESET_PAGE_LIST_ANSWER'
export const SET_PAGE_LIST_ANSWER = 'SET_PAGE_LIST_ANSWER'
export const SELECT_BEST_ANSWER = 'SELECT_BEST_ANSWER'
export const CHECK_FIRST_TIME_CREATE_QUESTION =
	'CHECK_FIRST_TIME_CREATE_QUESTION'
export const ADD_FAVORITE_QUESTION = 'ADD_FAVORITE_QUESTION'
export const DELETE_FAVORITE_QUESTION = 'ADD_FAVORITE_QUESTION'
export const OPEN_POPUP_FAVORITE_QUESTION = 'OPEN_POPUP_FAVORITE_QUESTION'
export const CLOSE_POPUP_FAVORITE_QUESTION = 'CLOSE_POPUP_FAVORITE_QUESTION'
export const GET_QUESTION_DETAIL = 'GET_QUESTION_DETAIL'
