export const PUSH_ONLINE_ALERT = '%s先生がオンラインになりました。'
export const PUSH_DAILY_BONUS = 'デイリーボーナスを受け取りました。'
export const PUSH_FAVORITE_USER_POST_TIMELINE =
	'%s先生が新しいタイムラインを投稿しました。'
export const PUSH_APPROVED_COMMENT = 'あなたのコメントは承認されました。'
export const PUSH_DENIED_COMMENT = 'あなたのコメントは否認されました。'
export const PUSH_APPROVED_SUBCOMMENT = 'あなたの返信は承認されました。'
export const PUSH_DENIED_SUBCOMMENT = 'あなたの返信は否認されました。'
export const PUSH_APPROVED_PROFILE =
	'あなたのプロフィール情報は承認されました。'
export const PUSH_DENIED_APART_USER_PROFILE =
	'あなたのプロフィール情報の一部は否認されました。'
export const PUSH_DENIED_PROFILE = 'あなたのプロフィール情報は否認されました。'
export const PUSH_REGISTER_NEW_DEVICE =
	'【%sPTプレゼント！】初回登録ありがとうございます'
export const PUSH_PAGE_SUPPORT = '事務局からお知らせが届きました'
export const PUSH_APPROVE_USER_QUESTION =
	'あなたの投稿した相談が掲載されました！先生の回答をお待ちください。'
export const PUSH_DENIED_USER_QUESTION =
	'あなたの投稿した相談は、事前審査の結果、取下げとなりました。内容を確認して投稿しなおしてください。'
export const PUSH_TELLER_ANSWER_USER_QUESTION =
	'あなたの相談に回答が付きました。確認してみましょう。'
export const PUSH_ANSWER_TIME_EXPIRED =
	'投稿した相談に対する回答期間が終了しました。ベストアンサーを決定してください。'
export const PUSH_QUESTION_INTEREST_HAS_ANSWER =
	'【教えて！先生】「気になる」質問に新しい回答がつきました！'
export const PUSH_QUESTION_INTEREST_TIME_EXPIRED =
	'【教えて！先生】「気になる」質問の回答が終了しました'
export const PUSH_QUESTION_INTEREST_HAS_BEST_ANSWER =
	'【教えて！先生】「気になる」質問のベストアンサーが決定！'
export const PUSH_RECEIVE_POINT = 'サイト登録でのポイントが加算されました'
export const PUSH_INTRODUCE_USER =
	'ご紹介ありがとうございます！ボーナスを付与しました。'
export const PUSH_BENCH_TIME_CHAT =
	'【通知】オフラインになりました。チャットルームにお戻りください。'

export const LABEL_NOTIFICATION_BACKEND = '運営から'
export const LABEL_NOTIFICATION_SYSTEM = 'システム通知'
export const LABEL_NOTIFICATION_STELLA_ANSWER = '教えて!先生'

export const MESSAGE_OLD_EMAIL_NOT_EXIST = '入力したメールは存在していません。'
export const MESSAGE_INVALID_EMAIL = 'メールが無効です。'
export const MESSAGE_NEW_EMAIL_IS_EXIST = '既に登録されています。'
export const MESSAGE_FACEBOOK_ID_NOT_REGISTED =
	'エラー\nFacebookIDは登録されていません。'
export const MESSAGE_INCORRECT_PASSWORD = 'パスワードが違います。'
export const MESSAGE_EMPTY_PASSWORD = 'パスワードは空白にしないでください。'
export const MESSAGE_INVALID_PASSWORD = 'パスワードは6文字以上にしてください。'
export const MESSAGE_VERIFY_CODE_EMPTY = '認証コードは空白にしないでください。'
export const MESSAGE_INVALID_VERIFY_CODE =
	'認証コードは正しく入力してください。'
export const MESSAGE_SEND_FILE_ERROR = '添付画像を選択してください'

export const MESSAGE_EMAIL_PASS_EMPTY =
	'メールアドレスとパスワードを入力してください。'
export const MESSAGE_OLD_PASSWORD_WRONG =
	'メールアドレスもしくはパスワードが違います。'
export const MESSAGE_EMAIL_HAS_EXIST = '既に登録されています。'
export const MESSAGE_PASS_NOT_SAME =
	'新しいパスワードと確認用パスワードが異なります。'
export const MESSAGE_CHANGE_PASS_SUCCESS = 'パスワードが変更されました。'
export const MESSAGE_CHANGE_EMAIL_SUCCESS = 'メールアドレスを変更しました。'
export const MESSAGE_CHANGE_EMAIL_PASS_SUCCESS =
	'メールアドレスとパスワードの登録に成功しました。'
export const MESSAGE_EMAIL_WRONG = 'メールが無効です。'
export const MESSAGE_PASSWORD_OUT_OF_RANGE =
	'パスワードは6文字以上にしてください。'
export const MESSAGE_EMAIL_EMPTY = 'メールを入力してください。'
export const MESSAGE_PASS_EMPTY = 'パスワードを入力してください。'
export const MESSAGE_BIRTHDAY_EMPTY = '生年月日が無効です。'
export const MESSAGE_BIRTHDAY_OUT_OF_RANGE = '18歳未満の方は登録できません。'
export const MESSAGE_NAME_OUT_OF_RANGE =
	'文字数が超過しました。○文字以内で入力ください。'
export const MESSAGE_NAME_EMPTY = '必ず入力してください。'
export const MESSAGE_REGISTER_SUCCESS =
	'「メールアドレスにパスワードを送信しました」'
export const MESSAGE_NAME_NOT_INVALID = '特殊文字は使用できません。'

export const MESSAGE_CONFIRM_BLOCK = '%sさんをブロックしますか？'
export const MESSAGE_CONFIRM_REPORT = '%sさんを通報しますか?'

export const MESSAGE_OVER_POINT =
	'ポイントが不足しております。\n ポイントを購入しますか？'
export const MESSAGE_BUY_POINT =
	'相談・リクエストには250pt以上が必要です。\n ポイントを購入しますか？'

export const MESSAEG_ALERT_USER_BLOCK =
	'現在このプロフィールにアクセス出来ません。やり直してください'

export const BUTTON_YES = 'はい'
export const BUTTON_NO = 'いいえ'
export const OK = 'OK'
export const BUTTON_CANCEL = 'キヤンセル'

export const ERROR_SYSTEM = 'システムエラー'
export const MESSAGE_EMAIL_HAS_EXIST_REGISTER =
	'メールアドレスが既に存在しております。'
export const TITLE_ERROR_REQUEST_CHAT = 'エラー'
export const MESSAGE_ERROR_REQUEST_CHAT =
	'正常に申請が行えませんでした。\nしばらくしてからもう一度お試しください。'
