export const GET_USERINFO = 'GET_USERINFO'
export const GET_BANNERS = 'GET_BANNERS'
export const GET_BANNERS_MYPAGE = 'GET_BANNERS_MYPAGE'
export const GET_REVIEWMODE = 'GET_REVIEWMODE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const GET_BLOCK_LIST = 'GET_BLOCK_LIST'
export const UNBLOCK = 'UNBLOCK'
export const BLOCK = 'BLOCK'
export const GET_POINT_CAMPAIGN_CODE = 'GET_POINT_CAMPAIGN_CODE'
export const CLEAR_DATA_BLOCK = 'CLEAR_DATA_BLOCK'
