import {
	CHANGE_TO_FAVORITE_LIST,
	RESET_FAVORITE_LIST,
} from './ChangeStateChatDetailActions'

const ChangeStateChatDetailReducer = (state, action) => {
	switch (action.type) {
		case CHANGE_TO_FAVORITE_LIST:
			return { ...state, isLoadFavoriteList: true }
		case RESET_FAVORITE_LIST:
			return { ...state, isLoadFavoriteList: false }
		default:
			return state
	}
}

export default ChangeStateChatDetailReducer
