export const RESPONSE_CODE = {
	SUCCESS: 0,
	UNKNOW_ERROR: 1,
	WRONG_DATA_FORMAT: 2,
	INVALID_TOKEN: 3,
	TOKEN_EXPIRED: 4,
	INVALID_ACCOUNT: 5,
	OLDEMAIL_NOT_EXIST: 6,
	INVALID_EMAIL: 7,
	NEWEMAIL_IS_EXIST: 8,
	OLDPASSWORD_INCORRECT: 9,
	INVALID_USER_NAME: 10,
	INVALID_BIRTHDAY: 11,
	UNUSABLE_EMAIL: 12,
	DUPLICATE_USER_NAME: 13,
	FACEBOOK_ID_IS_EXIST: 14,
	FACEBOOK_NOT_REGISTED: 15,
	INCORRECT_PASSWORD: 16,
	INVALID_PASSWORD: 17,
	TELLER_DEACTIVE: 18,
	UPLOAD_IMAGE_ERROR: 19,
	UPLOAD_FILE_ERROR: 20,
	ACCESS_DENIED: 21,
	WRONG_VERIFICATION_CODE: 22,
	USER_NOT_EXIST: 23,
	LOCKED_USER: 24,
	LOCK_FEATURE: 25,
	// NOT_ENOUGHT_POINT      : 26,
	PATNER_NOT_ENOUGHT_POINT: 27,
	BLOCK_USER: 28,
	WRONG_URL: 29,
	EMPTY_DATA: 30,
	INVALID_AUTHEN_CODE: 31,
	AUTHEN_CODE_EXPIRED: 32,

	TELLER_ID_NOT_EXIST: 34,
	INVALID_FORTUNE_TELLER_STATUS: 35,
	INVALID_FACEBOOK_TOKEN: 36,
	FORTUNE_TELLER_IS_CALLING: 37,
	INVALID_PUSH_TOKEN: 42,
	INVALED_DEVICE_ID: 43,
	USER_IS_DEACTIVATED: 48,
	REQUEST_IS_EXIST: 50,
	EMPTY_COMMENT_VALUE: 51,
	BUZZ_NOT_FOUND: 53,
	COMMENT_NOT_FOUND: 55,
	COMMENT_ID_EMPTY: 56,
	SUBCOMMENT_NOT_FOUND: 57,
	SUBCOMMENT_VALUE_EMPTY: 58,
	UNDER_18_YEARS_OLD: 61,
	EMAIL_CHANGED: 63,
	CODE_REVIEW_MODE_SETTING_NOT_SYN: 64,

	SERVER_MAINTAIN: 100,
	OUT_OF_DATE: 101,
	DISABLE_EMAIL: 102,
	IMAGE_NOT_FOUND: 103,

	FILE_NOT_FOUND: 106,
	EXISTS_DATA: 107,
	CM_CODE_EXISTS: 108,
	PERMISSION_DENIED: 109,
	OUT_OF_DATE_API: 200,
	NOT_ENOUGHT_POINT: 220,
	GOOGLE_NOT_REGISTER: 240,
	CONNECTION_ERROR: 1001,
	MAINTAIN_SERVER: 500,
}
