import {
	NOTICE_TYPE,
	TYPE_BACKEND,
	TYPE_STELLA_ANSWER,
} from '../constants/notice_type'
import {
	PUSH_ONLINE_ALERT,
	PUSH_DAILY_BONUS,
	PUSH_FAVORITE_USER_POST_TIMELINE,
	PUSH_APPROVED_COMMENT,
	PUSH_DENIED_COMMENT,
	PUSH_APPROVED_SUBCOMMENT,
	PUSH_DENIED_SUBCOMMENT,
	PUSH_APPROVED_PROFILE,
	PUSH_DENIED_APART_USER_PROFILE,
	PUSH_DENIED_PROFILE,
	PUSH_REGISTER_NEW_DEVICE,
	PUSH_PAGE_SUPPORT,
	PUSH_APPROVE_USER_QUESTION,
	PUSH_DENIED_USER_QUESTION,
	PUSH_TELLER_ANSWER_USER_QUESTION,
	PUSH_ANSWER_TIME_EXPIRED,
	PUSH_QUESTION_INTEREST_HAS_ANSWER,
	PUSH_QUESTION_INTEREST_TIME_EXPIRED,
	PUSH_QUESTION_INTEREST_HAS_BEST_ANSWER,
	PUSH_RECEIVE_POINT,
	PUSH_INTRODUCE_USER,
	PUSH_BENCH_TIME_CHAT,
	LABEL_NOTIFICATION_BACKEND,
	LABEL_NOTIFICATION_STELLA_ANSWER,
	LABEL_NOTIFICATION_SYSTEM,
} from '../constants/messages'
import { HOME, NOTIFICATION, MY_PAGE } from '../constants/pathName'

export default class Notification {
	constructor(attributes) {
		const notice = Object.assign({}, attributes || {})

		notice.type = notice.notificationType
		notice.notificationContent = getContent(notice)
		notice.isWebView = notice.redirectURL !== null
		notice.displayTypeClass = getDisplayTypeClass(notice.displayType)
		const convertURL = buildPageUrl(notice)?.res
		notice.redirectURL = notice.redirectURL
			? notice.redirectURL
			: convertURL?.pathname
		notice.stateURL = convertURL?.state
		notice.displayTypeText = getDisplayTypeText(notice.displayType)

		Object.keys(notice).forEach((key) => {
			this[key] = notice[key]
		})
	}
}

const convertText = (mgs, name) => {
	return mgs.replace('%s', name)
}

const buildPageUrl = (notice) => {
	let pathname = NOTIFICATION
	let state = {}
	if (notice.redirectURL) {
		return
	}

	switch (notice.type) {
		case NOTICE_TYPE.ONLINE_ALERT:
			pathname = `/teller-profile/${notice.friendId}`
			break
		case NOTICE_TYPE.DAILY_BONUS:
		case NOTICE_TYPE.REGISTER_NEW_DEVICE:
		case NOTICE_TYPE.LOGIN_BONUS:
		case NOTICE_TYPE.RECEIVE_POINT:
		case NOTICE_TYPE.INTRODUCE_USER:
		case NOTICE_TYPE.BONUS_POINT_REGULAR:
			pathname = HOME
			break
		case NOTICE_TYPE.APPROVED_USER_INFO:
		case NOTICE_TYPE.DENIED_PART_USER_INFO:
		case NOTICE_TYPE.DENIED_USER_INFO:
			pathname = MY_PAGE
			break
		//redirect link đến 1 màn hình nào đấy
		case NOTICE_TYPE.AUTO_NOTIFICATION:
			pathname = '/webview'
			state = notice.redirectURL
			break
		case NOTICE_TYPE.PAGE_SUPPORT:
			pathname = '/webview/help'
			break
		case NOTICE_TYPE.APPROVE_USER_QUESTION:
			pathname = `/answer/detail/${notice.questionId}`
			state = {
				tab: 2,
				questionId: notice.questionId,
				activeSlide: 0,
				paramsSearch: {},
				pageProp: 0,
				questions: [{ questionId: notice.questionId }],
				isRedirectFromCreateQuestion: true,
			}
			break
		case NOTICE_TYPE.DENIED_USER_QUESTION:
			pathname = '/answer'
			break
		case NOTICE_TYPE.TELLER_ANSWER_USER_QUESTION:
		case NOTICE_TYPE.ANSWER_TIME_EXPIRED:
		case NOTICE_TYPE.QUESTION_HAS_ANSWER:
		case NOTICE_TYPE.QUESTION_TIME_EXPIRED:
		case NOTICE_TYPE.QUESTION_BEST_ANSWER:
			pathname = `/answer/detail/${notice.questionId}`
			state = {
				tab: 2,
				questionId: notice.questionId,
				activeSlide: 0,
				paramsSearch: {},
				pageProp: 0,
				questions: [
					{
						questionId: notice.questionId,
						status: 12,
					},
				],
				isRedirectFromCreateQuestion: true,
			}
			break
		case NOTICE_TYPE.BENCH_TIME_CHAT:
			pathname = `/chat/detail/${notice.friendId}`
			break
		default:
			if (notice.postId) {
				pathname = `/timeline/${notice.postId}`
			}
			break
	}

	const res = {
		pathname,
		state,
	}

	return {
		res,
	}
}

const getDisplayTypeClass = (displayType) => {
	switch (displayType) {
		case TYPE_BACKEND:
			return 'type-backend'
		case TYPE_STELLA_ANSWER:
			return 'type-stella-answer'
		default:
			return 'type-system'
	}
}

const getDisplayTypeText = (displayType) => {
	switch (displayType) {
		case TYPE_BACKEND:
			return LABEL_NOTIFICATION_BACKEND
		case TYPE_STELLA_ANSWER:
			return LABEL_NOTIFICATION_STELLA_ANSWER
		default:
			return LABEL_NOTIFICATION_SYSTEM
	}
}

const getContent = (notice) => {
	switch (notice.type) {
		case NOTICE_TYPE.ONLINE_ALERT:
			return convertText(PUSH_ONLINE_ALERT, notice.friendName)
		case NOTICE_TYPE.DAILY_BONUS:
		case NOTICE_TYPE.LOGIN_BONUS:
			return PUSH_DAILY_BONUS
		case NOTICE_TYPE.FAVORITE_POST_TIMELINE:
			return convertText(
				PUSH_FAVORITE_USER_POST_TIMELINE,
				notice.friendName
			)
		case NOTICE_TYPE.APPROVED_COMMENT:
			return PUSH_APPROVED_COMMENT
		case NOTICE_TYPE.DENIED_COMMENT:
			return PUSH_DENIED_COMMENT
		case NOTICE_TYPE.APPROVE_SUB_COMMENT:
			return PUSH_APPROVED_SUBCOMMENT
		case NOTICE_TYPE.DENIED_SUB_COMMENT:
			return PUSH_DENIED_SUBCOMMENT
		case NOTICE_TYPE.APPROVED_USER_INFO:
			return PUSH_APPROVED_PROFILE
		case NOTICE_TYPE.DENIED_PART_USER_INFO:
			return PUSH_DENIED_APART_USER_PROFILE
		case NOTICE_TYPE.DENIED_USER_INFO:
			return PUSH_DENIED_PROFILE
		case NOTICE_TYPE.AUTO_NOTIFICATION:
		case NOTICE_TYPE.BONUS_POINT_REGULAR:
			return notice.notificationContent
		case NOTICE_TYPE.REGISTER_NEW_DEVICE:
			return convertText(PUSH_REGISTER_NEW_DEVICE, notice.point)
		case NOTICE_TYPE.PAGE_SUPPORT:
			return PUSH_PAGE_SUPPORT
		case NOTICE_TYPE.APPROVE_USER_QUESTION:
			return PUSH_APPROVE_USER_QUESTION
		case NOTICE_TYPE.DENIED_USER_QUESTION:
			return PUSH_DENIED_USER_QUESTION
		case NOTICE_TYPE.TELLER_ANSWER_USER_QUESTION:
			return PUSH_TELLER_ANSWER_USER_QUESTION
		case NOTICE_TYPE.ANSWER_TIME_EXPIRED:
			return PUSH_ANSWER_TIME_EXPIRED
		case NOTICE_TYPE.QUESTION_HAS_ANSWER:
			return PUSH_QUESTION_INTEREST_HAS_ANSWER
		case NOTICE_TYPE.QUESTION_TIME_EXPIRED:
			return PUSH_QUESTION_INTEREST_TIME_EXPIRED
		case NOTICE_TYPE.QUESTION_BEST_ANSWER:
			return PUSH_QUESTION_INTEREST_HAS_BEST_ANSWER
		case NOTICE_TYPE.RECEIVE_POINT:
			return PUSH_RECEIVE_POINT
		case NOTICE_TYPE.INTRODUCE_USER:
			return PUSH_INTRODUCE_USER
		case NOTICE_TYPE.BENCH_TIME_CHAT:
			return PUSH_BENCH_TIME_CHAT
		default:
			break
	}

	return ''
}
