import React, { useEffect } from 'react';
import { actions as AuthActions } from './contexts/Auth/AuthContext'

const BaseController = () => {

	// useEffect(() => {
	// 	const date = new Date()
	// 	const expiredRemainTime =
	// 				localStorage.getItem('expiredRemainTime')
	// 				// alert(expiredRemainTime)
	// 			if (
	// 				expiredRemainTime &&
	// 				// expiredRemainTime - 300000 <= date.getTime()
	// 				expiredRemainTime - 1800000 <= date.getTime()
	// 			) {
	// 				// Check before 5 minutes
	// 				AuthActions.refreshToken()
	// 			}
	// }, [])

	useEffect(() => {
		var interval
		const date = new Date()
		window.onfocus = () => {
			interval = setInterval(() => {
				const expiredRemainTime =
					localStorage.getItem('expiredRemainTime')
				if (
					expiredRemainTime &&
					expiredRemainTime - 300000 <= date.getTime()
				) {
					// Check before 5 minutes
					AuthActions.refreshToken()
				}
			}, 2000)
		}

		window.onblur = () => {
			clearInterval(interval)
			AuthActions.refreshToken()
		}
	}, [])
	
	return null
}

export default BaseController;