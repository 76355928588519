import { createSlice } from '@reduxjs/toolkit'
import { SAGAS_CONSTANTS } from '../../../sagas/sagasConstants'

const initialState = {
	dataPhoneNumber: {},
	dataCodeSMS: {},
	status: SAGAS_CONSTANTS.STATUS.IDLE,
}

const VerifySMSSlice = createSlice({
	name: 'VerifySMS',
	initialState,
	reducers: {
		doVerifyPhoneNumber: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.LOADING
		},
		doVerifyPhoneNumberSuccess: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.SUCCESS
			state.dataPhoneNumber = action.payload
		},
		doVerifyPhoneNumberFail: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.FAILURE
			state.dataPhoneNumber = action.payload
		},
		doVerifyCodeSMS: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.LOADING
		},
		doVerifyCodeSMSSuccess: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.SUCCESS
			state.dataCodeSMS = action.payload
		},
		doVerifyCodeSMSFail: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.FAILURE
			state.dataCodeSMS = action.payload
		},
		resetStatus: (state, action) => {
			state.status = SAGAS_CONSTANTS.STATUS.IDLE
		},
	},
})

export const {
	doVerifyPhoneNumber,
	doVerifyPhoneNumberSuccess,
	doVerifyPhoneNumberFail,
	doVerifyCodeSMS,
	doVerifyCodeSMSSuccess,
	doVerifyCodeSMSFail,
	resetStatus,
} = VerifySMSSlice.actions

export const VerifySMSSelector = (state) => state.VerifySMS

export default VerifySMSSlice.reducer
