import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
import {
	TELLER_PROFILE_DETAIL,
	TELLER_PROFILE_DEFAULT,
	ADD_FAVORITE,
	REMOVE_FAVORITE,
	GET_REVIEWS_TELLER,
	TELLER_PROFILE_POSTS,
	TELLER_POSTS_DEFAULT,
} from './TellerProfileActions'
import TellerProfileReducer from './TellerProfileReducer'

const defaultList = (dispatch) => () => {
	dispatch({ type: TELLER_PROFILE_DEFAULT })
}

const tellerProfile =
	(dispatch) =>
	(params = {}) => {
		return serverApi
			.get(appConfig.fortuner_teller + '/' + params.fortuneTellerId, {})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: TELLER_PROFILE_DETAIL,
						payload: response.data.data,
					})
				}
				return response
			})
	}

const addFavorite = (dispatch) => (friendId) => {
	serverApi.post(appConfig.path_favorite, { friendId }).then((response) => {
		if (response.data.code === 0) {
			dispatch({ type: ADD_FAVORITE })
		}
	})
}

const removeFavorite = (dispatch) => (friendId) => {
	serverApi
		.delete(appConfig.path_favorite + `?friendId=${friendId}`)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: REMOVE_FAVORITE })
			}
		})
}

const getReviewTeller =
	(dispatch) =>
	(tellerId, size = 200) => {
		serverApi
			.get(
				appConfig.path_reviews_teller +
					`?fortuneTellerId=${tellerId}&page=0&size=${size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: GET_REVIEWS_TELLER,
						payload: response.data.data,
					})
				}
			})
	}

const tellerProfilePosts =
	(dispatch) =>
	(params = []) => {
		let stringUrl = '?' + (params?.length > 0 ? params?.join('&') : '')
		serverApi
			.get(appConfig.fortuner_teller_posts + stringUrl)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({
						type: TELLER_PROFILE_POSTS,
						payload: response.data.data,
					})
				}
				return response
			})
	}

const defaultListPost = (dispatch) => () => {
	dispatch({ type: TELLER_POSTS_DEFAULT })
}

export const { Provider, Context, actions } = createDataContext(
	TellerProfileReducer,
	{
		tellerProfile,
		defaultList,
		addFavorite,
		removeFavorite,
		getReviewTeller,
		tellerProfilePosts,
		defaultListPost,
	},
	{ data: null, dataReviewTeller: [], dataPostTeller: null }
)
