import {
	GET_TOP_RANK_USER,
	GET_TELLER_LIST,
	GET_ANSWER_LIST,
	GET_TIMELINE_LIST,
	LIKE_POST,
	UNLIKE_POST,
	GET_NEWS,
	GET_USER,
} from './HomeActions'

const homeReducer = (state, action) => {
	switch (action.type) {
		case GET_USER:
			return { ...state, userProfile: action.payload }
		case GET_TOP_RANK_USER:
			return { ...state, topRankingList: action.payload }
		case GET_TELLER_LIST:
			return { ...state, tellerList: action.payload }
		case GET_NEWS:
			return { ...state, newsList: action.payload }
		case GET_ANSWER_LIST:
			return { ...state, answerList: action.payload }
		case GET_TIMELINE_LIST:
			return { ...state, timelineList: action.payload }
		case LIKE_POST:
			return { ...state, liked: action.payload }
		case UNLIKE_POST:
			return { ...state, liked: action.payload }
		default:
			return state
	}
}

export default homeReducer
