import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { GET_LIST_FAVORITE } from './FavoriteListActions'
import { appConfig } from '../../constants/generalConfig'
import FavoriteListReducer from './FavoriteListReducer'

const getListFavorite =
	(dispatch) =>
	(skip = 0) => {
		const params = {
			applicationName: 'stella',
			deviceType: 1,
			page: skip,
			size: 30,
			tab: 'favorite',
		}
		return serverApi
			.get(appConfig.fortuner_teller, { params })
			.then((response) => {
				dispatch({
					type: GET_LIST_FAVORITE,
					payload: response.data.data,
				})
			})
	}

export const { Provider, Context, actions } = createDataContext(
	FavoriteListReducer,
	{ getListFavorite },
	{ favoriteList: [] }
)
