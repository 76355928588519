import { all } from '@redux-saga/core/effects'
import ChatSaga from '../screens/Chat/ChatSagas/ChatSaga'
import GlobalScreenSaga from '../screens/GlobalScreen/GlobalScreenSagas/GlobalScreenSaga'
import MyPageSaga from '../screens/MyPage/MyPagaSagas/MyPageSaga'
import PointControllerSaga from '../screens/PointController/PointControllerSagas/PointControllerSaga'
import VerifySMSSaga from '../screens/VerifySMS/VerifySMSSagas/VerifySMSSaga'

export default function* rootSaga() {
	console.log(
		'%c  Saga is running! Please add X-Reducer to store.js and rootSaga to enable that reducers!!!!',
		'color: #0e93e0;background: #aaefe5;'
	)
	yield all([
		GlobalScreenSaga(),
		MyPageSaga(),
		ChatSaga(),
		VerifySMSSaga(),
		PointControllerSaga(),
	])
}
