import axios from 'axios'
import { appConfig } from '../constants/generalConfig'
import { hideLoading, showLoading } from '../util/functions'
import { clearStorage } from '../util/Fetch'
import { actions } from '../contexts/Auth/AuthContext'
import { RESPONSE_CODE } from '../constants/responseCode'

const LIMIT_REQUEST = 3
let isShow = false
let count = 0
const ApiNotLoading = ['/ums/v1/token', '/ums/v1/requests/reconnect']

const serverApi = axios.create({
	method: 'get', // default
	baseURL: appConfig.api_base_url,
})

const reAuthentication = async () => {
	const security = localStorage.getItem('_info')

	if (!security) {
		clearStorage()
		return
	}

	const password = security.substring(0, 32)
	const email = atob(security.substring(32))

	const data = {
		email,
		password,
	}

	const res = await actions.login(data, true)
	// alert(`res code 11111: ${JSON.stringify(res)}`)
	if (res?.data?.code === 0) {
		// alert(`res code 2222222: ${JSON.stringify(res)}`)
		window.location.reload(true)
		return
	}
	// alert(`security clear 2: ${security}`)
	// clearStorage()
}

serverApi.interceptors.request.use(
	async (config) => {
		const token = await localStorage.getItem('token')
		if (token) {
			config.headers.Authorization = `${token}`
		}

		if (!isShow && ApiNotLoading.indexOf(config.url) === -1) {
			// showLoading();
			isShow = true
		}
		return config
	},
	(error) => {
		// hideLoading();
		if (error.response && error.response.status === 500) {
			window.location.href = '/maintain'
		}
		return Promise.reject(error)
	}
)

serverApi.interceptors.response.use(
	(response) => {
		// hideLoading();
		isShow = false
		count = 0
		if (
			response.data.code === RESPONSE_CODE.USER_IS_DEACTIVATED ||
			response.data.code === RESPONSE_CODE.EMAIL_CHANGED ||
			response.data.code === RESPONSE_CODE.ACCESS_DENIED
		) {
			clearStorage()
			window.location.href = '/register'
		}
		if (
			response.data.code === RESPONSE_CODE.TOKEN_EXPIRED ||
			response.data.code ===
			RESPONSE_CODE.CODE_REVIEW_MODE_SETTING_NOT_SYN ||
			response.data.code === RESPONSE_CODE.INVALID_TOKEN
		) {
			reAuthentication()
		}

		return response
	},
	(error) => {
		// hideLoading();
		if (error.response && error.response.status === 500) {
			count += 1
			if (count >= LIMIT_REQUEST) window.location.href = '/maintain'
		}
		return Promise.reject(error)
	}
)

export default serverApi
