import {
	GET_TIMELINE_DETAIL,
	GET_COMMENT_LIST,
	GET_SUBCOMMENT_LIST,
	ADD_COMMENT,
	DELETE_COMMENT,
	ADD_SUB_COMMENT,
	DELETE_SUB_COMMENT,
} from './TimelineActions'
// import { actions } from './TimelineContext';

const timelineReducer = (state, action) => {
	const newCmtList = [...state.CommentList]
	const newTimeline = { ...state.TimelineDetail }

	switch (action.type) {
		case GET_TIMELINE_DETAIL:
			return { ...state, TimelineDetail: action.payload }
		case GET_COMMENT_LIST:
			return { ...state, CommentList: action.payload }
		case GET_SUBCOMMENT_LIST:
			return { ...state, SubCommentList: action.payload }
		case ADD_COMMENT:
			const newComment = action.payload

			newTimeline.commentNumber += 1
			newCmtList.push(newComment)
			return {
				...state,
				TimelineDetail: newTimeline,
				CommentList: newCmtList,
			}

		case DELETE_COMMENT:
			newTimeline.commentNumber -= 1
			newCmtList[action.payload].isDelete = true

			return {
				...state,
				CommentList: newCmtList,
				TimelineDetail: newTimeline,
			}
		case ADD_SUB_COMMENT:
			const newSubComment = action.payload
			newTimeline.commentNumber += 1

			newCmtList[action.indexReplyCmt].subcomments.unshift(newSubComment)

			return { ...state, CommentList: newCmtList }
		case DELETE_SUB_COMMENT:
			newTimeline.commentNumber -= 1
			const lengthSubCmt =
				newCmtList[action.indexReplyCmt].subcomments.length
			newCmtList[action.indexReplyCmt].subcomments[
				lengthSubCmt - action.indexSubCmt - 1
			].isDelete = true

			return { ...state, CommentList: newCmtList }
		default:
			return state
	}
}

export default timelineReducer
