import { GET_TELLER_INFO } from './TellerInfoActions'

const tellerInfoReducer = (state, action) => {
	switch (action.type) {
		case GET_TELLER_INFO:
			return { ...state, tellerInfo: action.payload }
		default:
			return state
	}
}

export default tellerInfoReducer
