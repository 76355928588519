import createDataContext from '../createDataContext'
import serverApi from '../../api/serverApi'
import { appConfig } from '../../constants/generalConfig'
// import { showLoading, hideLoading } from '../../util/functions';
import {
	GET_ALL_NOTIFICATION,
	GET_NOTIFICATION_FROM_TELLER,
	GET_NEWS,
	UPDATE_MAIN_TAB,
} from './NotificationActions'
import NotificationReducer from './NotificationReducer'
import Notification from '../../entities/Notification'

const getNotification =
	(dispatch) =>
	(target, page, size = 24) => {
		// showLoading();
		return serverApi
			.get(
				appConfig.path_get_notification +
					`/${target}?page=${page}&size=${size}`
			)
			.then((response) => {
				if (response.data.code === 0) {
					if (target === 'all') {
						response.data.data = response.data.data.map(
							(notice) => new Notification(notice)
						)
						dispatch({
							type: GET_ALL_NOTIFICATION,
							payload: response.data.data,
						})
						return
					}
					dispatch({
						type: GET_NOTIFICATION_FROM_TELLER,
						payload: response.data.data,
					})
					return response
					// hideLoading();
				}
			})
			.catch((err) => {
				// hideLoading();
				console.error(err)
			})
	}

const getNews = (dispatch) => () => {
	serverApi
		.get(appConfig.path_get_news)
		.then((response) => {
			if (response.data.code === 0) {
				dispatch({ type: GET_NEWS, payload: response.data.data })
				// hideLoading();
			}
		})
		.catch((err) => {
			// hideLoading();
			console.error(err)
		})
}

const updateMainTab = (dispatch) => (mainTab) => {
	dispatch({ type: UPDATE_MAIN_TAB, payload: mainTab })
}

export const { Provider, Context, actions } = createDataContext(
	NotificationReducer,
	{ getNotification, getNews, updateMainTab },
	{
		notificationAll: [],
		notificationTeller: [],
		listNews: [],
		mainTab: 'all',
	}
)
