import createDataContext from '../createDataContext'
import { OPEN_BUY_POINT, CLOSE_BUY_POINT } from './BuyPointAction'
import BuyPointReducer from './BuyPointReducer'

const openBuyPoint = (dispatch) => (isOverPoint) => {
	dispatch({ type: OPEN_BUY_POINT, payload: isOverPoint })
}

const closeBuyPoint = (dispatch) => () => {
	dispatch({ type: CLOSE_BUY_POINT })
}

export const { Provider, Context, actions } = createDataContext(
	BuyPointReducer,
	{ openBuyPoint, closeBuyPoint },
	{ isOpenBuyPoint: false, isOverPoint: false }
)
