import { appConfig } from '../../constants/generalConfig'
import serverApi from '../serverApi'

export const VerifySMSAPI = (...params) => {
	const verifyPhoneNumber = (params = {}) => {
		return serverApi.post(appConfig.path_verify_phone_number, params)
	}

	const verifyCodeSMS = (params = {}) => {
		return serverApi.put(appConfig.path_verify_phone_number, params)
	}

	return {
		verifyPhoneNumber,
		verifyCodeSMS,
	}
}
